import {
  formattedMessageForPricelog,
  generateAuthorSocialDataLink,
  generateBookDetailLink,
  generateCampaignLinkNew,
  textColorStatus,
} from '../utils/Helper';
import { _formatDate, FormatDateString, FormatDateTime, isValidDateFormat } from '../utils/DateTimeHelper';
import ormToast, { TOAST_TYPE } from '../utils/ormToast';
import {
  displayAuthorLink,
  dateColumnConfig,
  dateColumnWithTimeConfig,
  displayUserName,
  displayHtmlContent,
  displayTitleLink,
  getActionColumns,
} from '../utils/columnHelper';
import { BuildServerlessBookCoverImageUrl } from '../utils/ImageHelper';
import IMAGESIZES from './IMAGESIZES';
import { Markup } from 'interweave';
import { Box, IconButton, Switch, Tooltip, Typography, capitalize } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import {
  getDestinationInfoText,
  getFrequencyInfoText,
  renderTriggerHistoryCell,
} from 'src/components/TriggerTitles/triggerTitleHelper';
import { STRING_OPERATOR } from 'src/components/Shared/NominationConstants';
import TransactionTableItem from 'src/components/Activation/ServiceRequests/TransactionTableItem';
import { getNumericOperators } from 'src/components/Shared/Table/Filters/operators';
import { renderURLCell } from 'src/utils/tableValueGetter';
import { ExpandCircleDown } from '@mui/icons-material';

export const displayError = ({ value }) => (
  <div className={String(value)?.includes('[[ERROR]]') ? 'error-message' : ''}>
    {value ? String(value).replace('[[ERROR]]', '') : ''}
  </div>
);

const displayDateError = ({ value }) => {
  if (!value) return '';
  if (String(value)?.includes('[[ERROR]]')) {
    return <div className={'error-message'}>{value ? String(value).replace('[[ERROR]]', '') : ''}</div>;
  } else return <div>{FormatDateTime(value).format('MM/DD/YYYY')}</div>;
};

const renderStatus = ({ value }) => {
  if (value && value === 'Fail') {
    return (
      <span>
        <strong style={{ color: 'red' }}>{value}</strong>
      </span>
    );
  } else if (value && value === 'Success') {
    return (
      <span>
        <strong style={{ color: 'green' }}>{value}</strong>
      </span>
    );
  } else {
    return <span>{value}</span>;
  }
};

export const renderBooleanStatus = ({ value }) => {
  if (!value || value === 'false' || value === 'False') {
    return <span>False</span>;
  } else
    return (
      <span>
        <strong style={{ color: 'green' }}>{'True'}</strong>
      </span>
    );
};

export const AUDIO_CONTRACT_DEFAULT_COLUMNS = [
  { field: 'audioPublisher', headerName: 'Audio Publisher', width: 130, type: 'string' },
  {
    field: 'title',
    headerName: 'Title',
    width: 170,
    renderCell: ({ row }) => displayTitleLink(row.primary_isbn, row.title),
    type: 'string',
  },
  { field: 'primaryIsbn', headerName: 'ISBN', width: 130 },
  { field: 'primaryAuthorNames', headerName: 'Author Name', width: 130, type: 'string' },
  { field: 'agent', headerName: 'Agent', width: 100, type: 'string' },
  { field: 'pub_date', headerName: 'Publish Date', width: 100, ...dateColumnConfig },
  { field: 'imprint', headerName: 'Imprint', width: 130, type: 'string' },
  { field: 'bisacs', headerName: 'Bisacs', width: 200, valueGetter: value => value?.join(', '), type: 'string' },
  { field: 'dlp', headerName: 'DLP', width: 60, type: 'number', headerAlign: 'left', align: 'left' },
  { field: 'frontlist', headerName: 'FrontList', width: 80, type: 'string' },
  { field: 'territorydesc', headerName: 'Territory', width: 130, type: 'string' },
  { field: 'language', headerName: 'Language', width: 100, type: 'string' },
  { field: 'ormContractDate', headerName: 'ORM Contract Date', width: 100, ...dateColumnConfig },
  { field: 'ormAudioExpiration', headerName: 'ORM Audio Expiration', width: 100, ...dateColumnConfig },
  { field: 'ormAutoRenew', headerName: 'ORM Auto Renew', width: 70, type: 'string' },
  {
    field: 'ormAutoRenewTerm',
    headerName: 'ORM Auto Renew Term',
    type: 'number',
    headerAlign: 'left',
    align: 'left',
    width: 70,
  },
  { field: 'ormContractStatus', headerName: 'ORM Contract Status', width: 80, type: 'string' },
  { field: 'audioPublisherContractDate', headerName: 'Audio Publisher Contract Date', width: 100, ...dateColumnConfig },
  { field: 'audioPublisherExpiration', headerName: 'Audio Publisher Expiration', width: 100, ...dateColumnConfig },
  { field: 'audioPublisherAutoRenew', headerName: 'Audio Publisher Auto Renew', width: 70, type: 'string' },
  {
    field: 'audioPublisherAutoRenewTerm',
    headerName: 'Audio Publisher Auto Renew Term',
    width: 70,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
  },
  { field: 'audioPublisherContractStatus', headerName: 'Audio Publisher Contract Status', width: 80, type: 'string' },
  { field: 'audioBookPubDate', headerName: 'Audio Book Pub Date', width: 80, ...dateColumnConfig },
  { field: 'advance', headerName: 'Advance', width: 80, type: 'number', headerAlign: 'left', align: 'left' },
  { field: 'physicalRoyalties', headerName: 'Royalties on Physical', width: 70, type: 'string' },
  { field: 'digitalRoyalties', headerName: 'Royalties on Digital', width: 70, type: 'string' },
  { field: 'paySchedule', headerName: 'Pay Schedule', width: 100, type: 'string' },
  { field: 'comment', headerName: 'Comments', width: 100, type: 'string' },
];

export const FAILED_TITLES_COLUMNS = [
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'error', headerName: 'Error', flex: 1, type: 'string' },
];

export const serviceRequestErrorColumns = [
  {
    field: 'Service Type',
    headerName: 'Service Type',
    flex: 1,
    type: 'string',
    renderCell: displayError,
  },
  {
    field: 'Title',
    headerName: 'Title',
    flex: 1,
    type: 'string',
    renderCell: displayError,
  },
  {
    field: 'Authors',
    headerName: 'Authors',
    flex: 1,
    type: 'string',
    renderCell: displayError,
  },
  {
    field: 'ISBN',
    headerName: 'ISBN',
    flex: 1,
    renderCell: displayError,
  },
  {
    field: 'ASIN',
    headerName: 'Asin',
    flex: 1,
    type: 'string',
    renderCell: displayError,
  },
  {
    field: 'DLP',
    headerName: 'DLP',
    flex: 1,
    renderCell: displayError,
  },
  {
    field: 'SaleDLP',
    headerName: 'Sale DLP',
    flex: 1,
    renderCell: displayError,
  },
  {
    field: 'RunDate',
    headerName: 'Run Date',
    flex: 1,
    valueGetter: value => (value && isValidDateFormat(value) ? new Date(value) : value),
    renderCell: displayDateError,
  },
  {
    field: 'BusUnit',
    headerName: 'Business Unit',
    flex: 1,
    type: 'string',
    renderCell: displayError,
  },
  {
    field: 'Campaign Name',
    headerName: 'Campaign Name',
    flex: 1,
    type: 'string',
    renderCell: displayError,
  },
  {
    field: 'Url',
    headerName: 'Url',
    flex: 1,
    type: 'string',
    renderCell: displayError,
  },
  {
    field: 'Display Ads Type',
    headerName: 'Display Ads Type',
    flex: 1,
    type: 'string',
    renderCell: displayError,
  },
  {
    field: 'Start Date',
    headerName: 'Start Date',
    flex: 1,
    valueGetter: value => (value && isValidDateFormat(value) ? new Date(value) : value),
    renderCell: displayDateError,
  },
  {
    field: 'End Date',
    headerName: 'End Date',
    flex: 1,
    valueGetter: value => (value && isValidDateFormat(value) ? new Date(value) : value),
    renderCell: displayDateError,
  },
];

export const serviceRequestStatusColumns = [
  {
    field: 'serviceType',
    headerName: 'Service Type',
    width: 130,
    type: 'string',
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 130,
    type: 'string',
  },
  { field: 'primaryIsbn', headerName: 'ISBN', width: 80 },
  { field: 'asin', headerName: 'Asin', width: 80, type: 'string' },
  { field: 'authors', headerName: 'Authors', width: 120, type: 'string' },
  {
    field: 'dlp',
    headerName: 'DLP',
    width: 75,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'saleDlp',
    headerName: 'Sale DLP',
    width: 75,
    type: 'number',
    headerAlign: 'left',
    align: 'left',
  },
  { field: 'displayAdsType', headerName: 'Display Ads Type', width: 105, type: 'string' },
  {
    field: 'runDate',
    headerName: 'Run Date',
    width: 100,
    ...dateColumnConfig,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    width: 100,
    ...dateColumnConfig,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 100,
    ...dateColumnConfig,
  },
  { field: 'url', headerName: 'URL', width: 150, type: 'string' },
  { field: 'businessUnit', headerName: 'Business Unit', width: 75, type: 'string' },
  { field: 'campaignName', headerName: 'Campaign Name', width: 75, type: 'string' },
  {
    field: 'pubDate',
    headerName: 'Pub Date',
    width: 100,
    renderCell: renderStatus,
  },
  {
    field: 'bookcover',
    headerName: 'Fetch BookCover (Amazon)',
    width: 75,
    renderCell: renderStatus,
  },
  {
    field: 'savebook',
    headerName: 'Save Book',
    width: 75,
    renderCell: renderStatus,
  },
  {
    field: 'generateservicereq',
    headerName: 'Generate Service Req',
    width: 75,
    renderCell: renderStatus,
  },
  {
    field: 'emailnotification',
    headerName: 'Notify Marketing Team',
    width: 75,
    renderCell: renderStatus,
  },
  {
    field: 'failmessage',
    headerName: 'Reason',
    width: 115,
    renderCell: renderStatus,
  },
];

export const MASTER_SERVICE_REQUEST_TABLE_COLUMNS = [
  { field: 'transactionName', headerName: 'Transaction Name', type: 'string', flex: 1 },
  {
    field: 'clientInfo',
    headerName: 'Client',
    valueGetter: value => value?.name || '',
    type: 'string',
    flex: 0.7,
  },
  { field: 'cost', headerName: 'Cost', type: 'number', flex: 0.5, align: 'left', headerAlign: 'left' },
  { field: 'source', headerName: 'Source', type: 'string', flex: 0.5 },
  { field: 'paymentStatus', headerName: 'Payment Status', type: 'string', flex: 0.5 },
  {
    field: 'createdbyname',
    headerName: 'Created By',
    width: 100,
    valueGetter: value => {
      if (value) {
        if (value.first_name || value.last_name) {
          return value.first_name + ' ' + value.last_name;
        } else return value.email;
      } else return '';
    },
    type: 'string',
    flex: 1,
  },
  {
    field: 'transactionId',
    headerName: 'Total Services',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row?.childData?.length,
    flex: 0.5,
  },
];

export const MASTER_SERVICE_REQUEST_CHILD_TABLE_COLUMNS = [
  { field: 'serviceType', headerName: 'Service Type', width: 140, type: 'string' },
  { field: 'title', headerName: 'Title', width: 180, type: 'string' },
  { field: 'primaryIsbn', headerName: 'ISBN', width: 130, type: 'string' },
  { field: 'asin', headerName: 'ASIN', width: 130, type: 'string' },
  { field: 'authors', headerName: 'Authors', width: 130, type: 'string' },
  {
    field: 'campaign',
    headerName: 'Campaign',
    renderCell: ({ row }) => generateCampaignLinkNew(row?.campaign?._id, row?.campaign?.name),
    type: 'string',
  },
  { field: 'runDate', headerName: 'Run Date', ...dateColumnConfig },

  { field: 'dlp', headerName: 'DLP', type: 'number', headerAlign: 'left', align: 'left' },
  { field: 'saleDlp', headerName: 'Sale DLP', type: 'number', headerAlign: 'left', align: 'left' },
  { field: 'businessUnit', headerName: 'Business Unit', type: 'string' },
  {
    field: 'url',
    headerName: 'Url',
    width: 250,
    type: 'string',
    renderCell: ({ row, value }) => {
      const copyFeedUrl = url => {
        var textField = document.createElement('textarea');
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        ormToast('URL copied!', TOAST_TYPE.INFO);
      };
      let url = value;
      if (url && row.trackingId) {
        if (url.includes('?')) url += `&trackingId=${row.trackingId}`;
        else url += `?trackingId=${row.trackingId}`;
      }
      return (
        <div style={{ color: 'rgb(13, 110, 253)' }} onClick={() => copyFeedUrl(url)} title='Click to copy url'>
          {url}
        </div>
      );
    },
  },
  { field: 'displayAdsType', headerName: 'Display Ads Type', width: 100, type: 'string' },
  { field: 'startDate', headerName: 'Start Date', ...dateColumnConfig },
  { field: 'endDate', headerName: 'End Date', ...dateColumnConfig },
  { field: 'serviceStatus', headerName: 'Service Status', type: 'string' },
];

export const EXTERNAL_SUBMISSION_PARENT_COLUMNS = [
  { field: 'name', headerName: 'Submission Name', flex: 1, type: 'string' },
  {
    field: 'campaigntype',
    headerName: 'Campaign Type',
    flex: 1,
    type: 'string',

    renderCell: ({ row }) => {
      return row.campaigntypename ? row.campaigntypename : '';
    },
    valueGetter: (_, row) => row.campaigntypename,
  },
  {
    field: 'startdate',
    headerName: 'Start Date',
    flex: 0.5,
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    flex: 0.5,
    ...dateColumnConfig,
  },
  // { field: 'promotedtitles', headerName: 'Titles', flex: 1 },
  {
    field: 'createdbyname',
    headerName: 'Created By',
    flex: 1,
    type: 'string',
    renderCell: ({ row }) => {
      return row.createdbyname !== ''
        ? `${row.createdbyname || ''} ${row.createdbylastname || ''}`
        : row.createdbyemail;
    },
  },
  { field: 'createdat', headerName: 'Created At', flex: 1, ...dateColumnConfig },
];

export const EXTERNAL_SUBMISSION_CHILD_COLUMNS = [
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'primary_isbn', headerName: 'Primary ISBN', flex: 1, type: 'string' },

  { field: 'promo_price', headerName: 'Promo Price', flex: 1, type: 'string', headerAlign: 'left', align: 'left' },
  {
    field: 'country',
    headerName: 'Countries',
    flex: 1,
    valueGetter: (_, row) => row?.country?.map(x => x).join(', '),
  },

  // { field: 'country', headerName: 'Country', flex: 1 },
  { field: 'authors', headerName: 'Authors', flex: 1, type: 'string' },
  { field: 'bookbubcategory', headerName: 'Bookbub Category', flex: 1, type: 'string' },
  { field: 'alternatecategory', headerName: 'Alternate Category', flex: 1, type: 'string' },
  { field: 'publisher', headerName: 'Publisher', flex: 1, type: 'string' },
  // { field: 'notes', headerName: 'Notes', flex: 1 },
];

export const APPROVED_SUBMISSION_CHILD_COLUMNS = [
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'primary_isbn', headerName: 'Primary ISBN', width: 150, type: 'string' },
  { field: 'asin', headerName: 'ASIN', width: 150, type: 'string' },
  {
    field: 'startdate',
    headerName: 'Start Date',
    flex: 0.5,
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    flex: 0.5,
    ...dateColumnConfig,
  },
  {
    field: 'differenceSubmissionApproval',
    headerName: 'Difference',
    width: 250,
    type: 'string',
    renderCell: displayHtmlContent,
  },
  {
    field: 'action',
    headerName: 'Action',
    width: 250,
    type: 'string',
    renderCell: displayHtmlContent,
  },
  { field: 'promo_price', headerName: 'Promo Price', width: 100, type: 'number', headerAlign: 'left', align: 'left' },
  {
    field: 'country',
    headerName: 'Countries',
    flex: 1,
    valueGetter: (_, row) => row?.country?.map(x => x).join(', '),
  },
  { field: 'authors', headerName: 'Authors', flex: 1, type: 'string' },
  { field: 'submissionName', headerName: 'Submission Name', flex: 1, type: 'string' },
  { field: 'bookbubcategory', headerName: 'Bookbub Category', flex: 1, type: 'string' },
  { field: 'campaignName', headerName: 'Deal Team Notes', flex: 1, type: 'string' },
];

export const SCHEDULE_CAMPAIGN_CHILD_COLUMNS = [
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'customcategorieNames', headerName: 'Custom Category', flex: 1, type: 'string' },
  { field: 'awardName', headerName: 'Awards', flex: 1, type: 'string' },
  { field: 'rank', headerName: 'Amazon Rank', flex: 1 },
];

export const SCHEDULE_CAMPAIGN_COLUMNS = [
  { field: 'ruleType', headerName: 'Rule Type', flex: 1, type: 'string' },
  { field: 'ruleSetValue', headerName: 'Values', flex: 1.5, type: 'string' },
  { field: 'campaignname', headerName: 'Campaign Name', flex: 1, type: 'string' },
  { field: 'postSiteId', headerName: 'Promote To', flex: 1, type: 'string' },
  { field: 'postSlug', headerName: 'Slug', flex: 1, type: 'string' },
  { field: 'promotedTitles', headerName: 'Title Count', flex: 0.5 },
  { field: 'promotedDateAndTime', headerName: 'Published Date and Time', ...dateColumnWithTimeConfig },
  { field: 'processed', headerName: 'Post Updated', width: 100, valueGetter: value => (value ? 'Yes' : 'No') },
  getActionColumns([{ name: 'Delete', callbackFunction: onDeleteClick }]),
];

export const SCHEDULE_CAMPAIGN_COLUMNS_WITHOUT_ACTION = [
  { field: 'ruleType', headerName: 'Rule Type', flex: 1, type: 'string' },
  { field: 'ruleSetValue', headerName: 'Values', flex: 1.5, type: 'string' },
  { field: 'campaignname', headerName: 'Campaign Name', flex: 1, type: 'string' },
  { field: 'postSiteId', headerName: 'Promote To', flex: 1, type: 'string' },
  { field: 'postSlug', headerName: 'Slug', flex: 1, type: 'string' },
  { field: 'promotedTitles', headerName: 'Title Count', flex: 0.5 },
  { field: 'promotedDateAndTime', headerName: 'Published Date and Time', ...dateColumnWithTimeConfig },
  { field: 'processed', headerName: 'Post Updated', width: 100, valueGetter: value => (value ? 'Yes' : 'No') },
];

function onDeleteClick(row) {
  row?.handleDelete(row.id);
}

export const FRONT_LIST_TITLE_WIDGET_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    type: 'string',
    renderCell: ({ row }) => displayTitleLink(row.primary_isbn, row.title),
  },
  {
    field: 'authordetails',
    headerName: 'Author',
    flex: 1,
    type: 'string',
    renderCell: displayAuthorLink,
    valueGetter: value => value?.map(value => value.name).join(''),
  },
  { field: 'onsaledate', headerName: 'Sale Date', flex: 1, ...dateColumnConfig },
  { field: 'customcategories', headerName: 'Custom Categories', flex: 1, type: 'string' },
  { field: 'publisher', headerName: 'Publisher', flex: 1, type: 'string' },
  { field: 'frontlist', headerName: 'Frontlist', flex: 1, type: 'string' },
  { field: 'nextCampaignTypeName', headerName: 'Next Campaign Type', flex: 1, type: 'string' },
  { field: 'nextCampaignStartDate', headerName: 'Next Campaign Date', flex: 1, ...dateColumnConfig },
];

//Ignition Batch Widget Parent Table Columns
export const IGNITION_BATCH_WIDGET_COLUMNS = [
  { field: 'publisher', headerName: 'Publisher', flex: 1.5, type: 'string' },
  { field: 'batch', headerName: 'Batch', flex: 1, type: 'string' },
  { field: 'count', headerName: 'Title Count', flex: 0.5 },
  { field: 'onsaledate', headerName: 'Sale Date', flex: 0.5, ...dateColumnConfig },
];

//Ignition Batch Widget Child Table Columns
export const IGNITION_BATCH_WIDGET_CHILD_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    type: 'string',
    renderCell: ({ row }) => displayTitleLink(row.primary_isbn, row.title),
  },
  {
    field: 'customCategories',
    headerName: 'Custom Categories',
    type: 'string',
    flex: 1,
    minWidth: 200,
  },
  { field: 'authors', headerName: 'Authors', width: 150, type: 'string' },
  {
    field: 'latestRating',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Amazon Rating',
    width: 100,
  },
  {
    field: 'latestReviews',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Amazon Review',
    width: 120,
  },
  { field: 'awards', headerName: 'Awards', flex: 1, type: 'string' },
  { field: 'bookbubFollowerCount', headerName: 'BB Author Follower Count', flex: 1 },
  { field: 'nextCampaignStartDate', headerName: 'Next Campaign Start Date', flex: 0.5, ...dateColumnConfig },
  { field: 'nextCampaignTypeName', headerName: 'Next Campaign Type', flex: 0.5, type: 'string' },
];

export const TITLE_COUNT_WIDGET_COLUMNS = [
  { field: 'month', headerName: 'Month', flex: 1, type: 'string' },
  { field: 'year', headerName: 'Year', flex: 1 },
  { field: 'campaigncount', headerName: 'Total Campaign', flex: 1 },
  { field: 'titlecount', headerName: 'Title Count', flex: 1 },
];

export const ORION_SCHEDULAR_CAMPAIGN_COLUMNS = [
  { field: 'ruleType', headerName: 'Rule Type', flex: 1, type: 'string' },
  { field: 'ruleSetValue', headerName: 'Values', flex: 1.5, type: 'string' },
  { field: 'campaignname', headerName: 'Campaign Name', flex: 1, type: 'string' },
  { field: 'postSiteId', headerName: 'Promote To', flex: 1, type: 'string' },
  { field: 'postSlug', headerName: 'Slug', flex: 1, type: 'string' },
  { field: 'promotedTitles', headerName: 'Title Count', flex: 0.5 },
  { field: 'promotedDateAndTime', headerName: 'Published Date and Time', editable: true, ...dateColumnWithTimeConfig },
  { field: 'processed', headerName: 'Post Updated', width: 100, valueGetter: value => (value ? 'Yes' : 'No') },
];

export const CAMPAIGN_HOME_CHILD_COLUMNS = [
  {
    field: 'image',
    headerName: 'Book Image',
    renderCell: ({ row }) => {
      let bookCoverUrl = BuildServerlessBookCoverImageUrl(IMAGESIZES.BC_THUMBNAIL, row.image);
      return (
        <a href={`/book/${row.primary_isbn}`} rel='noreferrer' target='_blank'>
          <img style={{ width: '50px' }} alt='' src={bookCoverUrl} />
        </a>
      );
    },
    width: 50,
  },
  { field: 'title', headerName: 'Title', width: 150, type: 'string' },
  { field: 'authors', headerName: 'Authors', width: 150, type: 'string' },
  { field: 'primary_isbn', headerName: 'Primary ISBN', width: 130, type: 'string' },
  { field: 'amazon_isbn13', headerName: 'Amazon ISBN', width: 130, type: 'string' },
  { field: 'asin', headerName: 'ASIN', width: 130, type: 'string' },
  { field: 'publisher', headerName: 'Publisher', width: 150, type: 'string' },
  { field: 'batch', headerName: 'Batch', width: 150, type: 'string' },
  { field: 'dlp', headerName: 'DLP', type: 'number', headerAlign: 'left', align: 'left', width: 100 },
  { field: 'promoprice', headerName: 'Promo Price', type: 'number', headerAlign: 'left', align: 'left', width: 100 },
  { field: 'categories', headerName: 'Categories', width: 200, type: 'string' },
  { field: 'bisac', headerName: 'Bisacs', width: 200, type: 'string' },
  { field: 'retailers', headerName: 'Retailers', width: 100, type: 'string' },
  { field: 'countries', headerName: 'Countries', width: 100, type: 'string' },
  {
    field: 'titleLevelBaseline',
    headerName: 'Monthly Title Level Baseline',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'proratedTitleLevelMonthlyUplift',
    headerName: 'Prorated Monthly Title-Level Uplift',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  { field: 'notes1', headerName: 'Notes 1', width: 100 },
  { field: 'notes2', headerName: 'Notes 2', width: 100 },
  { field: 'attribute', headerName: 'Attribute', width: 100 },
  {
    field: 'triggerName',
    headerName: 'Trigger Title',
    width: 100,
  },
  { field: 'adUnit', headerName: 'Ad Unit', flex: 100, renderCell: renderBooleanStatus },
  { field: 'isFreeBook', headerName: 'Free', width: 100, renderCell: renderBooleanStatus },
  {
    field: 'isActivation',
    headerName: 'Activation',
    width: 100,
    renderCell: renderBooleanStatus,
  },
  { field: 'isBHFL', headerName: 'BHFL', width: 100, renderCell: renderBooleanStatus },
  {
    field: 'isBusinessPriority',
    headerName: 'Business Priority',
    width: 100,
    renderCell: renderBooleanStatus,
  },
];

export const haloRecommendationColumns = campTypes => [
  { field: 'authors', headerName: 'Authors', width: 150, type: 'string' },
  {
    field: 'series_name',
    headerName: 'Series Name',
    width: 150,
    type: 'string',
    renderCell: displayHtmlContent,
  },
  { field: 'volume', type: 'number', align: 'left', headerAlign: 'left', headerName: 'Series Volume', width: 100 },
  {
    field: 'title',
    headerName: 'Title',
    width: 200,
    type: 'string',
    renderCell: ({ row }) => displayTitleLink(row.primaryisbn, row.title),
  },
  { field: 'primaryisbn', headerName: 'Primary ISBN', width: 120, type: 'string' },
  { field: 'asin', headerName: 'ASIN', width: 120, type: 'string' },
  { field: 'dlp', type: 'number', align: 'left', headerAlign: 'left', headerName: 'US DLP', width: 100 },
  {
    field: 'promoPriceHalo',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Promo Price',
    width: 100,
  },
  { field: 'bundle', headerName: 'Bundle', width: 100, type: 'string' },
  {
    field: 'latestRating',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Amazon Rating',
    width: 100,
  },
  {
    field: 'latestReviews',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Amazon Review',
    width: 100,
  },
  { field: 'rank', type: 'number', align: 'left', headerAlign: 'left', headerName: 'Amazon Rank', width: 100 },
  {
    field: 'last30consumerPrice',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Average Consumer Price over the previous 30 days',
    width: 100,
  },
  {
    field: 'unitSalesOf30Days',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Units sold previous 30 days (All retailers)',
    width: 100,
  },
  {
    field: 'lastSameCampaignName',
    headerName: 'Same Campaign Type last Campaign name',
    width: 100,
    type: 'string',
  },
  {
    field: 'lastCampaignEndDate',
    headerName: 'Same Campaign Type last Campaign end date',
    ...dateColumnConfig,
  },
  {
    field: 'duringProceeds',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Same Campaign: Proceeds for last run date',
  },
  {
    field: 'standardDeviation',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Standard Deviation',
    width: 100,
  },
  {
    field: 'last3MonthDuringProceeds',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Same Campaign: Proceeds for last 3 available months[6month window]',
    width: 150,
  },
  {
    field: 'salesLiftDayOf',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    headerName: 'Same Campaign: Sales lift for last run date ',
    width: 100,
  },
  {
    field: 'CTRLastCampaignSameCamp',
    type: 'number',
    headerName: 'CTR - last campaign [Same Campaign Type]',
    width: 150,
  },
  {
    field: 'CPCLastCampaignSameCamp',
    type: 'number',
    headerName: 'CPC - last campaign [Same Campaign Type]',
    width: 150,
  },
  {
    field: 'UnitsLastCampaignSameCamp',
    type: 'number',
    headerName: 'Units during last campaign [Same Campaign Type]',
    width: 150,
  },
  {
    field: 'PostCampaignSalesLiftSameCamp',
    type: 'number',
    headerName: 'Post-campaign sales lift',
    width: 150,
  },
  {
    field: 'parentcamptype',
    headerName: 'Parent Promotion Type',
    width: 150,
    type: 'string',
    valueGetter: value => {
      if (value) {
        let cmp = campTypes.filter(ctype => ctype.value === value);
        return cmp[0]?.label;
      } else {
        return value;
      }
    },
  },
  {
    field: 'parentcampdate',
    headerName: 'Parent Promotion Date',
    ...dateColumnConfig,
  },
  {
    field: 'parentcampcountries',
    headerName: 'Country',
    width: 100,
    type: 'string',
    renderCell: ({ value }) => {
      if (value && value.length > 0) {
        return <Markup content={value.toString()} />;
      }
    },
  },
  {
    field: 'nextDate',
    headerName: 'Children Promotion Date',
    ...dateColumnConfig,
  },
  {
    field: 'flagged',
    headerName: 'Flag',
    width: 100,
    type: 'string',
    renderCell: ({ value }) => {
      if (value && value.length > 0) {
        return <Markup content={value.toString()} />;
      }
    },
  },
];

export const UPCOMING_CAMPAIGN_COLUMNS = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    type: 'string',
  },
  {
    field: 'campaigntypename[0].text',
    headerName: 'Type',
    flex: 1,
    type: 'string',
  },
  {
    field: 'startdate',
    headerName: 'Start Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'promotedtitles',
    headerName: 'Countries',
    flex: 1,
    type: 'string',
    renderCell: ({ value }) => {
      return <span>{value[0]?.countries?.map(x => x).join(',')}</span>;
    },
  },
];

export const TITLE_FORMAT_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, type: 'string' },
  { field: 'campaigntypename', headerName: 'Campaign Type', flex: 1, type: 'string' },
  { field: 'startdate', headerName: 'Start Date', flex: 1, ...dateColumnConfig },
  { field: 'enddate', headerName: 'End Date', flex: 1, ...dateColumnConfig },
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'authors', headerName: 'Authors', flex: 1, type: 'string' },
  { field: 'dlp', headerName: 'DLP', flex: 1, type: 'number', headerAlign: 'left', align: 'left' },
  { field: 'promoprice', headerName: 'Promo Price', flex: 1, type: 'number', headerAlign: 'left', align: 'left' },
  { field: 'countries', headerName: 'Countries', flex: 1, type: 'string' },
  { field: 'publisher', headerName: 'Publisher', flex: 1, type: 'string' },
  { field: 'batch', headerName: 'Batch', flex: 1, type: 'string' },
  { field: 'categories', headerName: 'Categories', flex: 1, type: 'string' },
  { field: 'retailers', headerName: 'Retailers', flex: 1, type: 'string' },
  { field: 'primary_isbn', headerName: 'ISBN', flex: 1, type: 'string' },
  { field: 'amazon_isbn13', headerName: 'Amazon ISBN', flex: 1, type: 'string' },
  { field: 'asin', headerName: 'ASIN', flex: 1, type: 'string' },
  { field: 'notes1', headerName: 'Notes 1', flex: 1, type: 'string' },
  { field: 'notes2', headerName: 'Notes 2', flex: 1, type: 'string' },
  { field: 'attribute', headerName: 'Attribute', flex: 1, type: 'string' },
  {
    field: 'tpid',
    headerName: 'Trigger Title',
    width: 100,
    renderCell: renderBooleanStatus,
  },
  { field: 'isFreeBook', headerName: 'Free', flex: 1, renderCell: renderBooleanStatus },
  {
    field: 'isActivation',
    headerName: 'Activation',
    flex: 1,
    renderCell: renderBooleanStatus,
  },
  { field: 'isBHFL', headerName: 'BHFL', flex: 1, renderCell: renderBooleanStatus },
  {
    field: 'isBusinessPriority',
    headerName: 'Business Priority',
    flex: 1,
    renderCell: renderBooleanStatus,
  },
];

export const DELETE_SELECTED_CAMPAIGN_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, type: 'string' },
  { field: 'campaigntypename', headerName: 'Campaign Type', flex: 1, type: 'string' },
  { field: 'startdate', headerName: 'Start Date', ...dateColumnConfig },
  { field: 'enddate', headerName: 'End Date', ...dateColumnConfig },
  { field: 'createdbyname', headerName: 'Created By', flex: 1, type: 'string' },
];

export const SEARCH_EXISTING_CAMPAIGN_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, type: 'string' },
  { field: 'campaigntypename', headerName: 'Campaign Type', flex: 1, type: 'string' },
  {
    field: 'titlecount',
    headerName: 'Title Count',
    align: 'center',
    headerAlign: 'center',
    width: 100,
  },
  { field: 'startdate', headerName: 'Start Date', ...dateColumnConfig },
  {
    field: 'enddate',
    headerName: 'End Date',
    width: 150,
    renderCell: ({ value }) => {
      if (value === null || value === '') return <span></span>;

      let today = new Date();
      let enddate = new Date(value);
      today.setHours(0, 0, 0, 0);
      enddate.setHours(0, 0, 0, 0);
      enddate.setDate(enddate.getDate() + 1);

      let color;
      enddate >= today ? (color = 'green') : (color = 'red');

      let date = FormatDateString(value, 'MM/DD/YYYY');
      return <span style={{ color: color, fontWeight: 'bold' }}>{date}</span>;
    },
    ...dateColumnConfig,
  },
  { field: 'createdbyname', headerName: 'Created By', flex: 1, type: 'string' },
];

export const CAMPAIGN_WARNING_COLUMNS = [
  { field: 'name', headerName: 'Campaign', flex: 1, type: 'string' },
  { field: 'title', headerName: 'Book', flex: 1, type: 'string' },
  { field: 'isbn', headerName: 'ISBN', flex: 0.6, type: 'string' },
  {
    field: 'startdate',
    headerName: 'Start Date',
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    ...dateColumnConfig,
  },
  { field: 'message', headerName: 'Warning', flex: 1.5, type: 'string' },
];

export const CAMPAIGN_ERROR_COLUMNS = [
  { field: 'name', headerName: 'Campaign', flex: 1, type: 'string' },
  { field: 'title', headerName: 'Book', flex: 1, type: 'string' },
  { field: 'isbn', headerName: 'ISBN', flex: 0.6, type: 'string' },
  {
    field: 'startdate',
    headerName: 'Start Date',
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    ...dateColumnConfig,
  },
  { field: 'message', headerName: 'Error', flex: 1.5, type: 'string' },
];

export const GOOGLE_ALERT_COLUMNS = [
  { headerName: 'Date', field: 'date', ...dateColumnConfig },
  {
    headerName: 'Title',
    field: 'article_title',
    type: 'string',
    flex: 1,
    renderCell: ({ value, row }) => (
      <a href={row.article_url} rel='noreferrer' target='_blank'>
        {value}
      </a>
    ),
  },
  { headerName: 'Domain', field: 'article_domain', type: 'string', flex: 0.5 },
];

export const getExternalSubmissionMoveTitleColumns = campaignTypes => {
  return [
    {
      headerName: 'Submission Name',
      field: 'name',
      flex: 1,
      type: 'string',
      renderCell: ({ value }) => {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      },
    },
    {
      headerName: 'Campaign Type',
      field: 'campaigntype',
      flex: 1,
      type: 'string',
      renderCell: ({ row }) => {
        let campaignType = campaignTypes.find(ct => ct.value === row.campaigntype);
        return campaignType.label ? campaignType.label : '';
      },
    },
    {
      headerName: 'Start Date',
      field: 'startdate',
      ...dateColumnConfig,
    },
    {
      headerName: 'End Date',
      field: 'enddate',
      ...dateColumnConfig,
    },
    { headerName: 'Created By', field: 'createdbyname', flex: 1, type: 'string' },
  ];
};

export const DELETE_CAMPAIGN_WARNING_COLUMNS = [
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'primaryIsbn', headerName: 'ISBN', flex: 1, type: 'string' },
  { field: 'authors', headerName: 'Authors', flex: 1, type: 'string' },
  { field: 'clientInfo', headerName: 'Client', valueGetter: value => value.name, flex: 1, type: 'string' },
  { field: 'businessUnit', headerName: 'Business Unit', flex: 1, type: 'string' },
  {
    field: 'campaign',
    headerName: 'Campaign',
    type: 'string',
    renderCell: ({ row }) => {
      if (!row.campaignId || !row.campaign) return;
      return <div>{generateCampaignLinkNew(row?.campaign?._id, row?.campaign?.name)}</div>;
    },
    flex: 1,
  },
  {
    field: 'runDate',
    headerName: 'Run Date',
    flex: 1,
    ...dateColumnConfig,
  },
];

export const FIREBRAND_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    type: 'string',
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 1,
    type: 'string',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    type: 'string',
    renderCell: ({ value }) => {
      if (value === 'failure') {
        return (
          <Typography color='error' variant='subtitle2'>
            {value?.join('')}
          </Typography>
        );
      } else {
        return (
          <Typography color='success' variant='subtitle2'>
            {value?.join(',')}
          </Typography>
        );
      }
    },
  },
  {
    field: 'date_processed',
    headerName: 'Date',
    flex: 1,
    // valueGetter: value  => FormatDateTime(value).format('MM/DD/YYYY HH:mm'),
    // ...dateColumnConfig,
    ...dateColumnWithTimeConfig,
  },
];

export const CREATE_CAMPAIGN_WARNING_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, type: 'string' },
  { field: 'campaigntype', headerName: 'Campaign Type', flex: 1, type: 'string' },
  { field: 'startdate', headerName: 'Start Date', ...dateColumnConfig },
  { field: 'enddate', headerName: 'End Date', ...dateColumnConfig },
  { field: 'title', headerName: 'Title', width: 150, type: 'string' },
  { field: 'isbn', headerName: 'ISBN', width: 130, type: 'string' },
  { field: 'message', headerName: 'Warning', flex: 2, type: 'string' },
];

export const CREATE_CAMPAIGN_ERROR_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, type: 'string' },
  { field: 'campaigntype', headerName: 'Campaign Type', flex: 1, type: 'string' },
  { field: 'conflictingCampaignTypeName', headerName: 'Coflicting Campaign Type', flex: 1, type: 'string' },
  { field: 'startdate', headerName: 'Start Date', ...dateColumnConfig, flex: 1 },
  { field: 'enddate', headerName: 'End Date', ...dateColumnConfig, flex: 1 },
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'isbn', headerName: 'ISBN', flex: 1, type: 'string' },
  { field: 'asin', headerName: 'ASIN', flex: 1, type: 'string' },
  { field: 'promoprice', headerName: 'Promo Price', flex: 1 },
  { field: 'message', headerName: 'Error', flex: 2, type: 'string' },
];

export const NOTIFY_CONFLICTING_CAMPAIGN_ERROR_TABLE_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, type: 'string' },
  { field: 'campaigntype', headerName: 'Campaign Type', flex: 1, type: 'string' },
  { field: 'startdate', headerName: 'Start Date', ...dateColumnConfig },
  { field: 'enddate', headerName: 'End Date', ...dateColumnConfig },
  { field: 'title', headerName: 'Title', width: 150, type: 'string' },
  { field: 'isbn', headerName: 'ISBN', width: 130, type: 'string' },
  { field: 'message', headerName: 'Error', flex: 1, type: 'string' },
  { field: 'sendTo', headerName: 'Sent to (Editable)', flex: 1, editable: true, type: 'string' },
  { field: 'note', headerName: 'Note (Editable)', flex: 1, editable: true, type: 'string' },
];

export const CLIENT_PROFILE_LANDING_COLUMNS = [
  { field: 'name', headerName: 'Client Name', flex: 1, type: 'string' },
  { field: 'publisher', headerName: 'Publisher', flex: 1, type: 'string' },
  { field: 'parentCompanyName', headerName: 'Parent Company', flex: 1, type: 'string' },
  { field: 'address', headerName: 'Address', flex: 1, type: 'string' },
  {
    field: 'countries',
    headerName: 'Countries',
    type: 'string',
    valueGetter: value => value?.join(', '),
    flex: 1,
  },
  { field: 'pricingModel', headerName: 'Pricing Model', flex: 1, type: 'string' },
  {
    field: 'createdbyname',
    headerName: 'Created By',
    flex: 1,
    type: 'string',
    valueGetter: displayUserName,
  },
];

export const PRIORITY_TITLE_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    width: 200,
    type: 'string',
    renderCell: ({ row }) => displayTitleLink(row.primaryIsbn, row.title),
  },
  {
    field: 'authornames',
    headerName: 'Authors',
    type: 'string',
    width: 150,
  },
  {
    field: 'publisher',
    headerName: 'Publisher',
    type: 'string',
    width: 150,
  },
  {
    field: 'primaryIsbn',
    headerName: 'ISBN',
    hideable: false,
    type: 'string',
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    hideable: false,
    type: 'string',
  },
  {
    field: 'batch',
    headerName: 'Batch #',
    hideable: false,
    type: 'string',
  },
  {
    field: 'bundle',
    headerName: 'Bundle',
    type: 'string',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'frontlist',
    headerName: 'Front list',
    hideable: false,
    type: 'string',
  },
  {
    field: 'publicdomain',
    headerName: 'Public Domain',
    hideable: false,
    type: 'string',
  },
  {
    field: 'dlp',
    headerName: 'DLP',
    hideable: false,
    valueGetter: (_, row) => row.US.dlp,
  },
  {
    field: 'bisacs',
    headerName: 'Bisacs',
    hideable: false,
    type: 'string',
  },
  {
    field: 'customCategoriesList',
    headerName: 'Custom Categories',
    type: 'string',
    flex: 1,
    minWidth: 200,
  },
  {
    field: 'onsaledate',
    headerName: 'On Sale Date',
    ...dateColumnConfig,
  },
  {
    field: 'lastCampaign',
    headerName: 'Last campaign',
    width: 200,
    type: 'string',
    valueGetter: (_, row) => row?.lastCampaignTypeName + ' ' + row?.lastCampaignStartDate,
  },
  {
    field: 'upcomingCampaigns',
    headerName: 'Upcoming campaigns',
    width: 200,
    type: 'string',
  },
  {
    field: 'lastSixMonthTotalSaleExcludingBB',
    headerName: 'Last 6-months sales',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 150,
    valueGetter: (_, row) => row.calculatedHistoricalData.lastSixMonthTotalSaleExcludingBB,
  },
  {
    field: 'priorToLastSixMonthTotalSaleExcludingBB',
    headerName: 'Prior to last 6-months sales',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 150,
    valueGetter: (_, row) => row.calculatedHistoricalData.priorToLastSixMonthTotalSaleExcludingBB,
  },

  {
    field: 'differenceInNumberExcludingBB',
    headerName: 'Difference in number',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 150,
    valueGetter: (_, row) => row.calculatedHistoricalData.differenceInNumberExcludingBB,
  },
  {
    field: 'differenceInPercentageExcludingBB',
    headerName: 'Difference in percentage',
    type: 'number',
    width: 150,
    valueFormatter: value => `${value} %`,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row.calculatedHistoricalData.differenceInPercentageExcludingBB,
  },
  {
    field: 'maxSalesInLastTwelveMonthExcludingBB',
    headerName: 'Maximum sales in last 12 months',
    valueGetter: (_, row) => row.calculatedHistoricalData.maxSalesInLastTwelveMonthExcludingBB,
    hideable: false,
    type: 'string',
  },
  {
    field: 'minSalesInLastTwelveMonthExcludingBB',
    headerName: 'Minimum sales in last 12 months',
    valueGetter: (_, row) => row.calculatedHistoricalData.minSalesInLastTwelveMonthExcludingBB,
    hideable: false,
    type: 'string',
  },
];

export const PRIORITY_AUTHOR_COLUMNS = itemtype => {
  let commonCol = [
    {
      field: 'lastSixMonthTotalSale',
      headerName: 'Last 6-months sales',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 180,
      valueGetter: (_, row) => row.calculatedHistoricalData.lastSixMonthTotalSale,
    },
    {
      field: 'priorToLastSixMonthTotalSale',
      headerName: 'Prior to last 6-months sales',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 180,
      valueGetter: (_, row) => row.calculatedHistoricalData.priorToLastSixMonthTotalSale,
    },
    {
      field: 'differenceInNumber',
      headerName: 'Difference in number',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 180,
      valueGetter: (_, row) => row.calculatedHistoricalData.differenceInNumber,
    },
    {
      field: 'differenceInPercentage',
      headerName: 'Difference in percentage',
      type: 'number',
      width: 180,
      valueFormatter: value => `${value} %`,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (_, row) => row.calculatedHistoricalData.differenceInPercentage,
    },
    {
      field: 'averageMonthlySalesLast12Months',
      headerName: 'Average monthly sales for last 12-months',
      type: 'number',
      width: 180,
      align: 'left',
      headerAlign: 'left',
      valueGetter: (_, row) => row.calculatedHistoricalData.averageMonthlySalesLast12Months,
    },
    {
      field: 'title',
      headerName: 'Title count',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      width: 100,
      valueGetter: (_, row) => row.calculatedHistoricalData?.incBooks?.length,
    },
    {
      field: 'calculatedHistoricalData.incBooks',
      headerName: 'incBooks',
      flex: 1,
      valueGetter: (_, row) => row.calculatedHistoricalData?.incBooks,
    },
  ];

  if (itemtype === 'authors') {
    return [
      {
        field: 'authorDisplayName',
        headerName: 'Author',
        type: 'string',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'customCategoriesList',
        headerName: 'Custom Categories',
        type: 'string',
        flex: 1.5,
        minWidth: 250,
      },
      {
        field: 'publisherName',
        headerName: 'Publishers',
        type: 'string',
        flex: 1,
        minWidth: 150,
      },
      ...commonCol,
    ];
  } else if (itemtype === 'publishers' || itemtype === 'batch') {
    const columns = [
      {
        field: 'publisherName',
        headerName: 'Name',
        type: 'string',
        flex: 1,
        minWidth: 150,
      },
      {
        field: 'batchNames',
        headerName: 'Batch',
        type: 'string',
        flex: 1.5,
        minWidth: 250,
      },
      ...commonCol,
    ];
    //As we need batch as link only for publishers
    if (itemtype === 'publishers') {
      columns[1].renderCell = ({ row }) => {
        const batchNamesArray = row && row.batchNames && row.batchNames.split(',');
        return (
          <div>
            {batchNamesArray &&
              batchNamesArray.map((batchName, index) => (
                <React.Fragment key={index}>
                  {index > 0 && ', '}
                  <Link
                    key={index}
                    to={{
                      pathname: `/reports/priority-report/batchdetail`,
                      search: `?pub=${encodeURIComponent(row.publisherName)}&itemType=batch&batch=${encodeURIComponent(
                        batchName
                      )}`,
                    }}
                    target={'_blank'}
                  >
                    {batchName}
                  </Link>
                </React.Fragment>
              ))}
          </div>
        );
      };
    }

    return columns;
  }
};

export const TITLE_FOR_AUTHOR_PUBLISHER_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    width: 200,
    renderCell: ({ row }) => displayTitleLink(row.primaryIsbn, row.title),
  },
  {
    field: 'authornames',
    headerName: 'Authors',
    type: 'string',
  },
  {
    field: 'publisher',
    headerName: 'Publisher',
    type: 'string',
  },
  {
    field: 'primaryIsbn',
    headerName: 'ISBN',
    width: 140,
    type: 'string',
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    hideable: false,
    type: 'string',
  },
  {
    field: 'batch',
    headerName: 'Batch #',
    hideable: false,
    type: 'string',
  },
  {
    field: 'bundle',
    headerName: 'Bundle',
    type: 'string',
    width: 100,
  },
  {
    field: 'frontlist',
    headerName: 'Front list',
    hideable: false,
    type: 'string',
  },
  {
    field: 'publicdomain',
    headerName: 'Public Domain',
    hideable: false,
    type: 'string',
  },
  {
    field: 'dlp',
    headerName: 'DLP',
    hideable: false,
    valueGetter: (_, row) => row.US.dlp,
  },
  {
    field: 'customCategoriesList',
    headerName: 'Custom Categories',
    type: 'string',
    flex: 1.5,
  },
  {
    field: 'bisacs',
    headerName: 'Bisacs',
    type: 'string',
  },
  {
    field: 'onsaledate',
    headerName: 'On Sale Date',
    ...dateColumnConfig,
  },
  {
    field: 'lastSixMonthTotalSale',
    headerName: 'Last 6-months sales',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    valueGetter: (_, row) => row.calculatedHistoricalData.lastSixMonthTotalSale,
  },
  {
    field: 'priorToLastSixMonthTotalSale',
    headerName: 'Prior to last 6-months sales',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    valueGetter: (_, row) => row.calculatedHistoricalData.priorToLastSixMonthTotalSale,
  },
  {
    field: 'differenceInNumber',
    headerName: 'Difference in number',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    valueGetter: (_, row) => row.calculatedHistoricalData.differenceInNumber,
  },
  {
    field: 'differenceInPercentage',
    headerName: 'Difference in percentage',
    type: 'number',
    flex: 1,
    valueFormatter: value => `${value} %`,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row.calculatedHistoricalData.differenceInPercentage,
  },
  {
    field: 'averageMonthlySalesLast12Months',
    headerName: 'Average monthly sales for last 12-months',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    valueGetter: (_, row) => row.calculatedHistoricalData.averageMonthlySalesLast12Months,
  },
  {
    field: 'maxSalesInLastTwelveMonthExcludingBB',
    headerName: 'Maximum sales in last 12 months excluding bookbub deals',
    valueGetter: (_, row) => row.calculatedHistoricalData.maxSalesInLastTwelveMonthExcludingBB,
    hideable: false,
  },
  {
    field: 'minSalesInLastTwelveMonthExcludingBB',
    headerName: 'Minimum sales in last 12 months excluding bookbub deals',
    valueGetter: (_, row) => row.calculatedHistoricalData.minSalesInLastTwelveMonthExcludingBB,
    hideable: false,
  },
];

export const AGGREGATED_HISTORICAL_DATA_LOG_COLUMNS = [
  {
    field: 'itemType',
    headerName: 'Item Type',
    flex: 1,
    type: 'string',
    valueGetter: value => capitalize(value),
  },
  {
    field: 'identifier',
    headerName: 'Identified',
    flex: 1,
    type: 'string',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    type: 'string',
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 1,
    type: 'string',
  },
  {
    field: 'updateDate',
    headerName: 'Updated Date',
    ...dateColumnWithTimeConfig,
  },
];

export const TRAFFIC_LIGHT_REPORT_COLUMNS = [
  { field: 'title', headerName: 'Job', flex: 1, type: 'string' },
  { field: 'frequency', headerName: 'Frequency', flex: 1, type: 'string' },
  {
    field: 'status',
    headerName: 'status',
    flex: 1,
    type: 'string',
    renderCell: renderStatus,
  },
  {
    field: 'startDate',
    headerName: 'Job Start',
    ...dateColumnWithTimeConfig,
  },
  {
    field: 'endDate',
    headerName: 'Job End',
    ...dateColumnWithTimeConfig,
  },
  { field: 'runBy', headerName: 'Job Run By', flex: 1, type: 'string' },
  {
    field: 'runDate',
    headerName: 'Run Date',
    ...dateColumnConfig,
  },
  {
    field: 'emailSentTo',
    headerName: 'Email Sent To',
    flex: 1,
    type: 'string',
  },
  {
    field: 'emailStatus',
    headerName: 'Email Status',
    flex: 1,
    type: 'string',
    renderCell: renderStatus,
  },
  { field: 'logs', headerName: 'Log', flex: 1, disableExport: true, filterable: false, type: 'string' },
];

export const TRIGGER_TITLE_CONFIGURATION_COLUMNS = [
  { field: 'name', headerName: 'Trigger Name', flex: 1, minWidth: 150, type: 'string' },
  {
    field: 'minTitleCount',
    headerName: 'Minimum Title Count',
    width: 65,
  },
  {
    field: 'maxTitleCount',
    headerName: 'Maximum Title Count',
    width: 65,
  },
  {
    field: 'destination',
    headerName: 'Destination',
    flex: 1,
    type: 'string',
    minWidth: 250,
    valueGetter: (_, row) =>
      getDestinationInfoText(row.destination, row.schedule, row.minTitleCount, row.maxTitleCount),
    renderCell: ({ row }) => (
      <div
        dangerouslySetInnerHTML={{
          __html: getDestinationInfoText(row.destination, row.schedule, row.minTitleCount, row.maxTitleCount),
        }}
      />
    ),
  },
  {
    field: 'schedule',
    headerName: 'Trigger Schedule',
    flex: 1,
    minWidth: 150,
    type: 'string',
    valueGetter: value => getFrequencyInfoText(value),
  },
  {
    field: 'lastRunDate',
    headerName: 'Last Run Date',
    ...dateColumnWithTimeConfig,
    width: 120,
    minWidth: 120,
  },
  {
    field: 'nextRunDate',
    headerName: 'Next Run Date',
    ...dateColumnWithTimeConfig,
    width: 120,
    minWidth: 120,
  },
  {
    field: 'user',
    headerName: 'Created By',
    flex: 1,
    minWidth: 120,
    type: 'string',
    valueGetter: value => value?.email,
  },
  {
    field: 'version',
    headerName: 'Version',
    minWidth: 60,
    type: 'number',
    align: 'center',
    headerAlign: 'center',
  },
  {
    field: 'approvers',
    headerName: 'Approvers',
    flex: 1,
    minWidth: 150,
    valueGetter: value => value?.map(approver => approver.label)?.join(', '),
  },
  {
    field: 'status',
    headerName: 'Approver Status',
    minWidth: 100,
    renderCell: ({ value }) => {
      return (
        <Typography variant='caption' color={textColorStatus(value)}>
          {value}
        </Typography>
      );
    },
  },
];

export const TRIGGER_TITLE_RESULT_PARENT_COLUMNS = [
  {
    field: 'name',
    headerName: 'Trigger Name',
    flex: 1,
    minWidth: 150,
    type: 'string',
    valueGetter: (_, row) => row?.triggerName,
  },
  {
    field: 'minTitleCount',
    headerName: 'Minimum Title Count',
    width: 130,
    valueGetter: (_, row) => row?.minTitleCount,
  },
  {
    field: 'maxTitleCount',
    headerName: 'Maximum Title Count',
    width: 130,
    valueGetter: (_, row) => row?.maxTitleCount,
  },
  {
    field: 'count',
    headerName: 'Trigger Result Count',
    width: 130,
    valueGetter: (_, row) => row?.triggerTitlesCount || 0,
  },
  {
    field: 'createdAt',
    headerName: 'Last Run Date',
    flex: 1,
    ...dateColumnWithTimeConfig,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 130,
    type: 'string',
  },
  {
    field: 'updatedCampaign',
    headerName: 'Total Titles Updated In Campaign',
    width: 130,
    valueGetter: (_, row) => row?.titlesUpdatedInCampaign || 0,
  },
  {
    field: 'campaignType',
    headerName: 'Destination Campaign Type',
    flex: 1,
    minWidth: 150,
    type: 'string',
    valueGetter: (_, row) => row?.campaignTypeName || '',
  },
  {
    field: 'campaignName',
    headerName: 'Destination Campaign Name',
    flex: 1,
    minWidth: 150,
    type: 'string',
    valueGetter: (_, row) => row?.campaignName || '',
    renderCell: ({ row }) => {
      if (row.campaignName) {
        return (
          <Link to={`/campaign/edit/${row.campaignId}`} target={'_blank'}>
            {row.campaignName}
          </Link>
        );
      }
    },
  },
  {
    field: 'startdate',
    headerName: 'Destination Start Date',
    type: 'date',
    width: 160,
    minWidth: 160,
    valueFormatter: value => FormatDateString(value, 'MM/DD/YYYY'),
    valueGetter: (_, row) => (isValidDateFormat(row?.campaignStartDate) ? new Date(row?.campaignStartDate) : null),
  },
  {
    field: 'enddate',
    headerName: 'Destination End Date',
    type: 'date',
    width: 160,
    minWidth: 160,
    valueFormatter: value => FormatDateString(value, 'MM/DD/YYYY'),
    valueGetter: (_, row) => (isValidDateFormat(row?.campaignEndDate) ? new Date(row?.campaignEndDate) : null),
  },
];

export const TRIGGER_TITLE_RESULT_CHILD_COLUMNS = [
  { field: 'primary_isbn', headerName: 'ISBN', width: 130, type: 'string' },
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'publisher', headerName: 'Publisher', flex: 0.5, type: 'string' },
  {
    field: 'customcategories',
    headerName: 'ORM Categories',
    flex: 1,
    type: 'string',
    valueGetter: (_, row) => row?.customcategories?.map(value => value?.Name)?.join(', ') || '',
  },
  {
    field: 'authors',
    headerName: 'Author',
    flex: 0.5,
    type: 'string',
    valueGetter: (_, row) =>
      row?.authors?.find(value => value.role === 1)?.display_name || row.primaryAuthorNames || '',
  },
  {
    field: 'campaign',
    headerName: 'Is Part Of Campaign',
    flex: 0.5,
    valueGetter: (_, row) => (row?.campaignId ? true : false),
  },
];

export const BOOKBUB_COST_CONFIGURATION_COLUMNS = [
  {
    headerName: 'Bookbub Custom Category',
    field: 'bookbubcat',
    type: 'string',
    flex: 1,
    valueGetter: (_, row) => (row?.bookbubcat?.[0]?.vendorcategory ? row.bookbubcat[0].vendorcategory?.join(',') : ''),
  },
  { headerName: 'Minimum Price', field: 'minimumPrice', flex: 1 },
  { headerName: 'Maximum Price', field: 'maximumPrice', flex: 1 },
  { headerName: 'Cost', field: 'cost', flex: 1 },
  {
    headerName: 'Country',
    field: 'country',
    type: 'string',
    flex: 1,
  },
];

export const BOOKBUB_PAGE_COUNT_CONFIGURATION_COLUMNS = [
  {
    headerName: 'Bookbub Custom Category',
    field: 'bookbubcat',
    type: 'string',
    flex: 1,
    valueGetter: value => {
      if (value && value.length) {
        return value.map(v => `${v.vendorcategory}`).join(',\n');
      } else {
        return '';
      }
    },
  },
  { headerName: 'Group Name', field: 'groupName', flex: 1, type: 'string' },
  { headerName: 'Page Count', field: 'pageCount', flex: 1 },
];

export const BOOKBUB_PRIORITY_CONFIGURATION_COLUMNS = [
  {
    headerName: 'Priority',
    field: 'priority',
    flex: 1,
    type: 'string',
  },
  {
    headerName: 'Publishers',
    field: 'publishers',
    flex: 1.5,
    type: 'string',
    valueGetter: value => {
      return value?.join(',');
    },
  },
  {
    headerName: 'Publisher Operator',
    field: 'publishersoperator',
    flex: 0.5,
    type: 'string',
    valueGetter: value => {
      const operator = STRING_OPERATOR.find(item => item.value === value);
      return operator ? operator.label : '';
    },
  },
  { headerName: 'Public Domain', field: 'publicdomain', flex: 0.5, type: 'string' },
  {
    headerName: 'Ignition',
    field: 'ignition',
    flex: 0.5,
    type: 'string',
  },
  { headerName: 'On Sale Date Start Date ', field: 'onsalestartdate', flex: 1, ...dateColumnConfig },
  { headerName: 'On Sale Date End Date ', field: 'onsaleenddate', flex: 1, ...dateColumnConfig },
];

export const SDS_BOOKBUB_CATEGORY_DATA_COLUMNS = [
  {
    field: 'category',
    headerName: 'Bookbub Category',
    type: 'string',
    flex: 1,
  },
  {
    field: 'count',
    headerName: 'Title Count',
    type: 'number',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
];

export const SDS_CUT_RATE_DATA_COLUMNS = [
  {
    field: 'cutrate',
    headerName: 'Orim Cut Rate',
    type: 'string',
    flex: 1,
  },
  {
    field: 'sumofcost',
    headerName: 'SUM of Cost',
    type: 'number',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'percentage',
    headerName: 'Percentage',
    type: 'string',
    flex: 1,
  },
];

export const SDS_PRIORITY_DATA_COLUMNS = [
  {
    field: 'priority',
    headerName: 'Bookbub Priority',
    type: 'string',
    flex: 1,
  },
  {
    field: 'sumofcost',
    headerName: 'SUM of Cost',
    type: 'number',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'percentage',
    headerName: 'Percentage',
    type: 'string',
    flex: 1,
  },
];

export const SDS_GRAND_TOTAL_DATA_COLUMNS = [
  {
    field: 'name',
    headerName: 'Grand Total',
    type: 'string',
    flex: 1,
  },
  {
    field: 'value',
    headerName: '',
    type: 'number',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
];

export const DLP_PRICE_UPDATE_VIEW_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    minWidth: 150,
    type: 'string',
    valueGetter: (_, row) => row.bookInfo?.title || row.parentBook?.title,
  },
  { field: 'primaryisbn', headerName: 'Primary ISBN', minWidth: 140, type: 'string' },
  { field: 'parentIsbn', headerName: 'Prent ISBN', minWidth: 140, type: 'string' },
  {
    field: 'allowedCountriesForDlp',
    headerName: 'Allowed Countries',
    minWidth: 150,
    type: 'string',
    valueGetter: value => value?.join(', '),
  },
  {
    field: 'disallowedCountriesForDlp',
    headerName: 'Disallowed Countries',
    minWidth: 150,
    type: 'string',
    valueGetter: value => value?.join(', '),
  },
  { field: 'startdate', headerName: 'Start date', ...dateColumnConfig },
  { field: 'price', headerName: 'New Dlp', minWidth: 100 },
  { field: 'format', headerName: 'Book Format', minWidth: 150 },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    type: 'string',
    renderCell: ({ value }) => {
      return (
        <Typography variant='caption' color={textColorStatus(value)}>
          {capitalize(value)}
        </Typography>
      );
    },
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 1.5,
    minWidth: 150,
    type: 'string',
    renderCell: ({ row }) => {
      return (
        <Typography variant='caption' color={textColorStatus(row.status)}>
          {row.reason ? row.reason + ', ' + formattedMessageForPricelog(row) : formattedMessageForPricelog(row)}
        </Typography>
      );
    },
  },
];

export const PRICELOG_VIEW_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    minWidth: 170,
    flex: 1,
    type: 'string',
    valueGetter: (_, row) => row.bookInfo?.title || row.parentBook?.title,
  },
  { field: 'primaryisbn', headerName: 'Primary ISBN', minWidth: 120, type: 'string' },
  { field: 'parentIsbn', headerName: 'Parent ISBN', minWidth: 120, type: 'string' },

  {
    field: 'name',
    headerName: 'Campaign Name',
    minWidth: 170,
    flex: 1,
    type: 'string',
    valueGetter: (_, row) => row.campaigndetail?.name,
    renderCell: ({ row }) => {
      if (row.campaigndetail?.name) {
        return (
          <Link to={`/campaign/edit/${row.campaignId}`} target={'_blank'}>
            {row.campaigndetail?.name}
          </Link>
        );
      }
    },
  },
  { field: 'startdate', headerName: 'Start date', ...dateColumnConfig, minWidth: 60 },
  { field: 'enddate', headerName: 'End date', ...dateColumnConfig, minWidth: 60 },
  { field: 'createdat', headerName: 'Created At', ...dateColumnConfig, minWidth: 60 },
  {
    field: 'country',
    headerName: 'Country',
    minWidth: 80,
    type: 'string',
    renderCell: ({ row }) => {
      if (row.country) {
        return row.country;
      } else {
        return (
          <Box>
            <Box>
              <Typography variant='caption'>
                Allowed: <b>{row.allowedCountriesForDlp?.join(',')}</b>
              </Typography>
            </Box>
            <Box>
              <Typography variant='caption'>
                Disallowed: <b>{row.disallowedCountriesForDlp?.join(',')}</b>
              </Typography>
            </Box>
          </Box>
        );
      }
    },
  },
  { field: 'retailers', headerName: 'Retailers', minWidth: 80 },
  { field: 'price', headerName: 'Promo Price', minWidth: 80 },
  { field: 'format', headerName: 'Book Format', width: 120 },

  { field: 'priceChangeType', headerName: 'Price Change Type', minWidth: 80, type: 'string' },

  {
    field: 'status',
    headerName: 'Status',
    minWidth: 120,
    type: 'string',
    renderCell: ({ value, row }) => {
      return (
        <Tooltip title={row.reason}>
          <Typography variant='caption' color={textColorStatus(value)}>
            {capitalize(value)} {row.isDeleted ? '(Deleted)' : ''}
          </Typography>
        </Tooltip>
      );
    },
  },
  {
    field: 'message',
    headerName: 'Message',
    flex: 1,
    minWidth: 150,
    type: 'string',
    renderCell: ({ row }) => {
      return (
        <Typography variant='caption' color={textColorStatus(row.status)}>
          {row.reason ? row.reason + ', ' + formattedMessageForPricelog(row) : formattedMessageForPricelog(row)}
        </Typography>
      );
    },
    valueGetter: (_, row) =>
      row.reason ? row.reason + ', ' + formattedMessageForPricelog(row) : formattedMessageForPricelog(row),
  },
  { field: 'batchId', headerName: 'Batch Id', minWidth: 100, flex: 1, type: 'string' },
  {
    field: 'campaignId',
    headerName: 'Campaign Id',
    hideable: false,
    disableExport: true,
  },
];

export const CAMPAIGN_HOME_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, minWidth: 100, type: 'string' },
  { field: 'campaigntypename', headerName: 'Campaign Type', flex: 1, minWidth: 100, type: 'string' },
  {
    field: 'titlecount',
    headerName: 'Title Count',
    type: 'number',
    flex: 0.5,
    minWidth: 100,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'startdate',
    headerName: 'Start Date',
    flex: 0.5,
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    flex: 0.5,
    renderCell: ({ value }) => {
      if (value === null || value === '') return <span></span>;

      let today = new Date();
      let enddate = new Date(value);
      today.setHours(0, 0, 0, 0);
      enddate.setHours(0, 0, 0, 0);
      enddate.setDate(enddate.getDate() + 1);

      let color;
      enddate >= today ? (color = 'green') : (color = 'red');

      let date = _formatDate(value, 'MM/dd/yyyy');
      return <span style={{ color: color, fontWeight: 'bold' }}>{date}</span>;
    },

    ...dateColumnConfig,
  },
  { field: 'schedulercode', headerName: 'Scheduler Code', flex: 1, type: 'string' },
];

export const MONTHLY_REPORT_COLUMNS = handleTransactionItemClick => [
  {
    field: 'type',
    headerName: 'Type',
    flex: 1,
    type: 'string',
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1,
    type: 'string',
  },
  {
    field: 'invoiceSentDate',
    headerName: 'Invoice Sent Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'businessUnit',
    headerName: 'Class',
    flex: 1,
    type: 'string',
    formatter: (cell, row) => {
      if (row.businessUnit && row.businessUnit.length > 0) {
        return row.businessUnit.join(', ');
      }
    },
  },
  {
    field: 'item',
    headerName: 'Item',
    flex: 1,
    type: 'string',
  },
  {
    field: 'clientInfoName',
    headerName: 'PO #',
    flex: 1,
    type: 'string',
    valueGetter: (_, row) => row?.clientInfo?.contactInfo?.map(contact => contact.name)?.join(','),
  },
  {
    field: 'clientInfoEmail',
    headerName: 'Contact Email',
    flex: 1,
    type: 'string',
    valueGetter: (_, row) => row?.clientInfo?.contactInfo?.map(contact => contact.email)?.join(','),
  },
  {
    field: 'cost',
    headerName: 'Amount',
    flex: 1,
    renderCell: ({ row, value }) => (
      <TransactionTableItem
        transactionId={row._id}
        handleTransactionItemClick={transactionId => handleTransactionItemClick(transactionId)}
      >
        {value}
      </TransactionTableItem>
    ),
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    flex: 1,
    type: 'string',
  },
  {
    field: 'clientInfo',
    headerName: 'Description',
    flex: 1,
    type: 'string',
    valueGetter: value => {
      const info = value.contactInfo?.length > 0 && value.contactInfo[0];
      if (info) {
        return info.name || info.email;
      }
    },
  },
];

export const SERVICE_REQUEST_EDITABLE = (businessUnitOptions, displayAdsTypeOptions) => [
  {
    field: 'serviceType',
    headerName: 'Service Type',
    width: 130,
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 200,
    type: 'string',
  },
  {
    field: 'primaryIsbn',
    headerName: 'ISBN',
    width: 140,
  },
  {
    field: 'authors',
    headerName: 'Authors',
    type: 'string',
    width: 150,
  },
  {
    field: 'dlp',
    headerName: 'DLP',
    width: 100,
    editable: true,
    type: 'number',
  },
  {
    field: 'saleDlp',
    headerName: 'Sale DLP',
    editable: true,
    type: 'number',
  },

  {
    field: 'businessUnit',
    headerName: 'Business Unit',
    editable: true,
    valueOptions: businessUnitOptions,
    type: 'singleSelect',
  },
  {
    field: 'campaign',
    headerName: 'Campaign',
    type: 'string',
    renderCell: ({ row }) => (
      <div>{row?.campaign?.name ? generateCampaignLinkNew(row?.campaign?._id, row?.campaign?.name) : ''}</div>
    ),
  },
  {
    field: 'url',
    headerName: 'Url',
    type: 'text',
    width: 300,
    editable: true,
  },
  {
    field: 'displayAdsType',
    headerName: 'Display Ads Type',
    width: 150,
    valueOptions: displayAdsTypeOptions,
    type: 'singleSelect',
    valueGetter: value => value || '',
  },
  {
    field: 'runDate',
    headerName: 'Run Date',
    editable: true,
    ...dateColumnConfig,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    editable: true,
    ...dateColumnConfig,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    editable: true,
    ...dateColumnConfig,
  },
  {
    field: 'serviceStatus',
    headerName: 'Service Status',
    type: 'string',
    width: 120,
  },
];

export const SERVICE_REQUEST_TABLE = (handleTransactionItemClick, generateBookDetailLink) => [
  {
    field: 'clientInfo',
    headerName: 'Client',
    width: 150,
    valueGetter: value => value.name,
    type: 'string',
    renderCell: ({ row }) => (
      <TransactionTableItem
        transactionId={row.transactionId}
        handleTransactionItemClick={transactionId => handleTransactionItemClick(transactionId)}
      >
        {row.clientInfo?.name}
      </TransactionTableItem>
    ),
  },
  {
    field: 'serviceType',
    headerName: 'Service Type',
    width: 110,
    type: 'string',
  },
  {
    field: 'title',
    headerName: 'Title',
    width: 250,
    type: 'string',
    renderCell: ({ row }) => generateBookDetailLink(row.primaryIsbn, row.title),
  },
  {
    field: 'primaryIsbn',
    headerName: 'ISBN',
    width: 140,
    type: 'string',
  },
  {
    field: 'asin',
    headerName: 'ASIN',
    width: 130,
    type: 'string',
  },
  {
    field: 'authors',
    headerName: 'Authors',
    width: 230,
    type: 'string',
  },
  {
    field: 'campaign',
    headerName: 'Campaign',
    type: 'string',
    valueGetter: value => value?.name,
    renderCell: ({ row }) => generateCampaignLinkNew(row?.campaign?._id, row?.campaign?.name),
  },
  {
    field: 'runDate',
    headerName: 'Run Date',
    ...dateColumnConfig,
  },

  {
    field: 'dlp',
    headerName: 'DLP',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'saleDlp',
    headerName: 'Sale DLP',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'cost',
    headerName: 'Cost',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    renderCell: ({ row, value }) => (
      <TransactionTableItem
        transactionId={row.transactionId}
        handleTransactionItemClick={transactionId => handleTransactionItemClick(transactionId)}
      >
        {value}
      </TransactionTableItem>
    ),
    filterOperators: getNumericOperators(),
  },
  {
    field: 'businessUnit',
    headerName: 'Business Unit',
    type: 'string',
  },

  {
    field: 'url',
    headerName: 'Url',
    width: 250,
    renderCell: renderURLCell,
  },
  {
    field: 'displayAdsType',
    headerName: 'Display Ads Type',
    width: 100,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    ...dateColumnConfig,
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    ...dateColumnConfig,
  },
  {
    field: 'pubDate',
    headerName: 'Pub Date',
    ...dateColumnConfig,
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 85,
    type: 'string',
  },
  {
    field: 'paymentStatus',
    headerName: 'Payment Status',
    type: 'string',
  },

  {
    field: 'serviceStatus',
    headerName: 'Service Status',
    type: 'string',
  },

  {
    field: 'transactionName',
    headerName: 'Transaction Name',
    width: 300,
    type: 'string',
  },

  {
    field: 'createdbyname',
    headerName: 'Created By',
    width: 150,
    type: 'string',
    valueGetter: value => {
      if (value) {
        if (value.first_name || value.last_name) {
          return value.first_name + ' ' + value.last_name;
        } else return value.email;
      } else return '';
    },
  },
];

export const SOLD_SLOT_CONFIG_COLUMNS = campaignTypes => [
  {
    field: 'campaigntype',
    headerName: 'Campaign Type',
    flex: 1,
    type: 'string',
    valueGetter: value => {
      if (value) {
        let ct = campaignTypes.find(campaignType => campaignType.value === value);
        return ct?.label;
      }
    },
  },
  { field: 'campaignname', headerName: 'Campaign Name', flex: 1, type: 'string' },
  {
    field: 'createdbyname',
    headerName: 'Created By',
    flex: 1,
    type: 'string',
    valueGetter: displayUserName,
  },
];

export const TITLE_ERROR_COLUMN = {
  field: 'title',
  headerName: 'Title',
  width: 130,
  type: 'string',
};

export const BOOK_META_DATA_COLUMNS = [
  { field: 'primary_isbn', headerName: 'ISBN', width: 130, type: 'string' },
  { field: 'title', headerName: 'Title', flex: 0.5, type: 'string' },
  { field: 'newsletter_copy', headerName: 'Existing Strapline', flex: 1, type: 'string' },
  { field: 'chatgpt', headerName: 'ChatGPT Strapline', flex: 1.5, type: 'string' },
];

export const BLOCK_LIST_COLUMNS = [
  {
    field: 'publishers',
    headerName: 'Publishers',
    flex: 1,
    valueGetter: value => value?.join(', '),
    type: 'string',
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    renderCell: ({ row }) => {
      const priamryIsbn = row.primary_isbn;
      const title = row.Books[0]?.title || row.title;
      return displayTitleLink(priamryIsbn, title);
    },
    type: 'string',
  },
  { field: 'primary_isbn', headerName: 'ISBN', width: 150, type: 'string' },
  { field: 'campaigntypeexceptionsName', headerName: 'Allowed Campaign Type', flex: 1, type: 'string' },
  { field: 'blockedCampaignTypesName', headerName: 'Blocked Campaign Type', flex: 1, type: 'string' },
  { field: 'countries', headerName: 'Blocked Territory', width: 150, type: 'string' },
  {
    field: 'start_date',
    headerName: 'Start Date',
    flex: 0.5,
    ...dateColumnConfig,
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    flex: 0.5,
    ...dateColumnConfig,
  },
];

export const BOOK_DETAIL_AMAZON_QUOTE_COLUMNS = [
  {
    field: 'review',
    headerName: 'Review',
    flex: 1,
    type: 'string',
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1,
    type: 'string',
  },
];

export const BOOK_DETAIL_COLUMNS = [
  {
    field: 'awardname',
    headerName: 'Name',
    flex: 1,
    type: 'string',
  },
  {
    field: 'awardstatus',
    headerName: 'Status',
    flex: 1,
    type: 'string',
  },
  {
    field: 'awardnote',
    headerName: 'Note',
    flex: 1,
    type: 'string',
  },
];

export const AMAZON_TABLE_COULMN = [
  {
    field: 'country',
    headerName: 'Country',
    flex: 1,
    type: 'string',
  },
  {
    field: 'amazon_rank',
    headerName: 'Rank',
    flex: 1,
  },
  {
    field: 'amazon_review',
    headerName: 'Rating',
    flex: 1,
  },
  {
    field: 'amazon_review_count',
    headerName: 'Review Count',
    flex: 1,
    type: 'string',
  },
];

export const SEARCH_RESULTS_TABLE_COUMNS = bookColumnFormatter => [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    renderCell: ({ value, row }) => bookColumnFormatter(value, row, 'title'),
  },
  {
    field: 'authors',
    headerName: 'Authors',
    flex: 1,
    renderCell: ({ value, row }) => bookColumnFormatter(value, row, 'authors'),
  },
];

export const ADD_EVENT_POST_DATA_COLUMNS = postColumnFormatter => [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    renderCell: ({ value, row }) => postColumnFormatter(value, row, 'title'),
  },
  {
    field: 'site_id',
    headerName: 'Site Name',
    flex: 1,
    renderCell: ({ value, row }) => postColumnFormatter(value, row, 'siteName'),
  },
];

export const CAMPAIGN_TYPE_TABLE_COLUMNS = [
  {
    headerName: 'Campaign Type',
    field: 'text',
    type: 'string',
    flex: 1,
  },
  {
    headerName: 'Type',
    field: 'typevalue',
    type: 'string',
    flex: 1,
  },
  {
    headerName: 'Site',
    field: 'site',
    type: 'string',
    flex: 1,
  },
  {
    headerName: 'Priority',
    field: 'priority',
    type: 'Number',
    flex: 1,
  },

  {
    headerName: 'End Date Optional',
    field: 'optionalenddate',
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    valueGetter: value => (value ? 'Yes' : 'No'),
    flex: 1,
  },
  {
    headerName: 'Exclude from TSD Report',
    field: 'excludeFromTSD',
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    valueGetter: value => (value ? 'Yes' : 'No'),
    flex: 1,
  },
  {
    headerName: 'Default Retailers For Draft',
    field: 'defaultretailersfordraft',
    valueGetter: value => value?.join(', '),
    flex: 1,
    type: 'string',
  },
  {
    headerName: 'Default Countries For Draft',
    field: 'defaultcountriesfordraft',
    type: 'string',
    valueGetter: value => value?.join(', '),
    flex: 1,
  },
  {
    headerName: 'Notify Team Members',
    field: 'notify',
    type: 'string',
    flex: 1,
  },
  {
    headerName: 'Notify Campaign Error',
    field: 'campaignErrorNotify',
    type: 'string',
    flex: 1,
  },
];

export const CAMPAIGN_TYPE_EDIT_COLUMNS = (setSelectedDraftColumn, setDraftColumnMode) => [
  {
    field: 'column',
    headerName: 'Column Name',
    flex: 1.5,
    type: 'string',
    valueGetter: (_, row) => row.column.label,
  },
  {
    field: 'excludeExport',
    headerName: 'Exclude Country Data from Customized Draft Export',
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <input
            style={{ cursor: 'pointer' }}
            type='checkbox'
            checked={row.excludeExport}
            onChange={e => {
              setSelectedDraftColumn({
                column: row.column,
                excludeCountryData: row.excludeCountryData,
                excludeExport: e.target.checked,
              });
              setDraftColumnMode('edit');
            }}
          />
        </div>
      );
    },
  },
  {
    field: 'excludeCountryData',
    headerName: 'Exclude Country Data from Draft',
    flex: 1,
    renderCell: ({ row }) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <input
            style={{ cursor: 'pointer' }}
            type='checkbox'
            disabled={(row.column && row.column.value && !row.column.value.isCountryBasedData) || row.excludeExport}
            checked={row.excludeCountryData}
            onChange={e => {
              setSelectedDraftColumn({
                column: row.column,
                excludeExport: row.excludeExport,
                excludeCountryData: e.target.checked,
              });
              setDraftColumnMode('edit');
            }}
          />
        </div>
      );
    },
  },
];

export const FACEBOOK_AD_COLUMNS = [
  {
    field: 'awardname',
    headerName: 'Name',
    flex: 1,
    type: 'string',
  },
  {
    field: 'awardstatus',
    headerName: 'Status',
    flex: 1,
    type: 'string',
  },
];

export const FACEBOOK_AD_MANAGER_COLUMNS = [
  {
    headerName: 'Ad Name',
    field: 'adName',
    flex: 2,
    type: 'string',
  },
  { headerName: 'Budget ($)', field: 'dailyBudget', flex: 1, type: 'string' },
  { headerName: 'Spend', field: 'spend', flex: 1, type: 'string' },
  { headerName: 'Frequency', field: 'frequency', flex: 1, type: 'string' },
  { headerName: 'Impressions', field: 'impressions', flex: 1, type: 'string' },
  { headerName: 'Link Clicks', field: 'linkClicks', flex: 1, type: 'string' },
  { headerName: 'CTR Pct', field: 'ctrPct', flex: 1, type: 'string' },
  { headerName: 'CPC', field: 'cpc', flex: 1, type: 'string' },
  { headerName: 'During Proceeds', field: 'duringProceeds', flex: 1, type: 'string' },
  { headerName: 'SalesLift', field: 'salesLift', flex: 1, type: 'string' },
];

export const FACEBOOK_AD_SET_COLUMNS = campaignStatus => [
  { headerName: 'AdSet Name', field: 'adsetName', flex: 1, type: 'string' },
  { headerName: 'AdSet Country', field: 'country', flex: 1, type: 'string' },
  { headerName: 'Budget ($)', field: 'dailyBudget', flex: 1 },
  { headerName: 'Spend', field: 'adsetSpend', flex: 1 },
  { headerName: 'CTRPct', field: 'adsetCtrPct', flex: 1 },
  { headerName: 'CPC', field: 'adsetCpc', flex: 1 },
  { headerName: 'During Proceeds', field: 'adsetDuringProceeds', flex: 1, type: 'string' },
  { headerName: 'SalesLift', field: 'adsetSalesLift', flex: 1, type: 'string' },
  {
    field: '_id',
    headerName: 'Actions',
    headerAlign: 'center',
    align: 'center',
    type: 'string',
    renderCell: ({ row }) => {
      let campaignStatusArray = ['ACTIVE'];
      if (campaignStatusArray.includes(campaignStatus)) {
        return (
          <div style={{ textAlign: 'center' }}>
            <Tooltip title='Clone'>
              <IconButton size='small' onClick={() => this.handleCloneAdSet(row, 'adset')}>
                <ExpandCircleDown color='error' />
              </IconButton>
            </Tooltip>
          </div>
        );
      }
    },
  },
];

export const FACEBOOK_AD_CAMPAIGN_TABLE_COLUMN = [
  {
    headerName: 'Title',
    field: 'title',
    flex: 1.5,
    type: 'string',
  },
  {
    headerName: 'Campaign',
    field: 'campaignName',
    flex: 1.5,
    type: 'string',
    renderCell: ({ row }) => (
      <a
        target='__blank'
        href={`${process.env.REACT_APP_FACEBOOK_BUSINESS_URL}/adsmanager/manage/campaigns/edit?${process.env.REACT_APP_FACEBOOK_ACCOUNT_ID}&business_id=${process.env.REACT_APP_FACEBOOK_BUSINESS_ID}&selected_campaign_ids=${row.campaignID}`}
      >
        {row.campaignName}
      </a>
    ),
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    headerName: 'Budget ($)',
    field: 'budget',
    flex: 1,
  },
  { headerName: 'Spend', field: 'spend', flex: 1 },
  { headerName: 'CTRPct', field: 'ctrpct', flex: 1 },
  { headerName: 'CPC', field: 'cpc', flex: 1 },
  { headerName: 'During Proceeds', field: 'duringProceeds', flex: 1 },
  {
    headerName: 'ORIM Proceeds',
    field: 'orimProceeds',
    flex: 1,
    type: 'string',
  },
  { headerName: 'SalesLift', field: 'saleslift', flex: 1, type: 'string' },
  { headerName: 'Campaign Status', field: 'campaignStatus', flex: 1, type: 'string' },
];

export const CC_MAPPING_COLUMNS = [
  {
    headerName: 'Custom Category',
    field: 'custcat',
    type: 'string',
    flex: 1,
    valueGetter: value => value.map(x => x.Name)?.join(', '),
  },
  {
    headerName: 'Bisacs',
    field: 'bisacs',
    type: 'string',
    flex: 1,
    valueGetter: value => value?.join(', '),
  },
  { headerName: 'Vendor', field: 'vendor', flex: 0.5 },
  {
    headerName: 'Vendor Category',
    field: 'vendorcategory',
    type: 'string',
    flex: 1,
    valueGetter: value => value?.join(', '),
  },
  {
    headerName: 'Countries',
    field: 'countries',
    type: 'string',
    flex: 1,
    valueGetter: value => value?.join(', '),
  },
];

export const PROMO_PRICE_COLUMNS = [
  {
    field: 'range',
    headerName: 'Name',
    flex: 0.5,
    type: 'string',
    valueGetter: value => value?.join('-'),
  },
  { field: 'minDLP', headerName: 'Minimum DLP(range)', flex: 0.5 },
  {
    field: 'maxDLP',
    headerName: 'Maximum DLP(range)',
    flex: 0.5,
  },
  {
    field: 'promoprice',
    headerName: 'Promo Price',
    flex: 0.5,
  },
  { field: 'bookTypes', headerName: 'Book Type', flex: 0.5, type: 'string' },
  {
    field: 'countries',
    headerName: 'Countries',
    flex: 0.5,
    type: 'string',
    valueGetter: value => value?.join(', '),
  },
  {
    field: 'author_name',
    headerName: 'Author',
    flex: 0.5,
    type: 'string',
    valueGetter: (_, row) => {
      let displayName = [];
      if (row?.author_name) {
        row.author_name.forEach(authors_list => {
          displayName.push(authors_list.display_name);
        });
        return displayName.toString();
      }
    },
  },
  {
    field: 'amazonRankString',
    headerName: 'Amazon Rank',
    flex: 0.5,
    type: 'string',
    valueGetter: value => value || '',
  },
  {
    field: 'campaigntypenames',
    headerName: 'Campaign Type',
    flex: 1,
    type: 'string',
  },
  {
    field: 'bookbubcategorynames',
    headerName: 'Bookbub Category',
    flex: 1,
    type: 'string',
  },
  {
    field: 'followerCountString',
    headerName: 'Bookbub Follower Count',
    flex: 1,
    type: 'string',
  },
];

export const UPLOAD_BOOK_COLUMNS = [
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'multipleAuthors', headerName: 'Authors', flex: 1, type: 'string' },
  { field: 'publisher', headerName: 'Publisher', flex: 1, type: 'string' },
  { field: 'primary_isbn', headerName: 'Primary ISBN', width: 130, type: 'string' },
  { field: 'asin', headerName: 'ASIN', width: 130, type: 'string' },
  { field: 'image', headerName: 'Book Cover', flex: 1.5 },
  { field: 'sale_dlp', headerName: 'Sale DLP', flex: 0.5 },
  { field: 'price', headerName: 'Price', flex: 0.5 },
  { field: 'google_id', headerName: 'Google Id', flex: 0.5, type: 'string' },
  { field: 'bn_primary_isbn', headerName: 'B&N ISBN', flex: 1, type: 'string' },
  { field: 'bisacs', headerName: 'BISAC', flex: 1, type: 'string' },
  { field: 'newsletter_copy', headerName: 'EBB Newsletter Description', flex: 1, type: 'string' },
];

export const MILE_STONE_COLUMNS = [
  {
    field: 'title',
    headerName: 'Event Name',
    type: 'string',
    renderCell: ({ row }) => {
      if (!row.active) return <s>{row.title}</s>;
      return <span>{row.title}</span>;
    },
    flex: 1,
  },
  { field: 'type', headerName: 'Type', type: 'string', flex: 1 },
  {
    field: 'frequency',
    headerName: 'Frequency',
    type: 'string',
    flex: 1,
  },
  {
    field: 'allDay',
    headerName: 'All Day',
    type: 'bool',
    flex: 1,
  },
  {
    field: 'contentSite',
    headerName: 'Site',
    sort: true,
    valueGetter: value => value.join(', '),
    flex: 1,
  },
  {
    field: 'start',
    headerName: 'Start Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'end',
    headerName: 'End Date',
    flex: 1,
    ...dateColumnConfig,
  },
];

export const CUSTOM_CATEGORIES_MODAL_COLUMNS = [
  {
    field: 'category',
    headerName: 'Category',
    type: 'string',
    flex: 1,
  },
  {
    field: 'count',
    headerName: 'Title Count',
    type: 'number',
    flex: 1,
    align: 'left',
    headerAlign: 'left',
  },
];

export const LOAD_PERFERENCE_MODAL_COLUMNS = togglePreferenceDefault => [
  { headerName: 'Name', field: 'name', type: 'string', flex: 1 },
  {
    headerName: 'Is Default',
    field: 'isDefault',
    flex: 0.5,
    renderCell: ({ value, row }) => {
      return <Switch value={value} checked={value} onChange={() => togglePreferenceDefault(row)} id='default-switch' />;
    },
  },
];

export const NOMINATION_FILTER_TRACKING_COLUMNS = [
  { field: '_id', headerName: 'Filters', flex: 2, type: 'string' },
  { field: 'count', headerName: 'Count', flex: 1 },
];

export const NOMINATION_RESULTS_FIXED_COLUMNS = isInPersonalDraft => [
  { field: 'primary_isbn', headerName: 'Primary_ISBN', type: 'string', hideable: false },
  { field: 'amazon_isbn13', headerName: 'Amazon_ISBN', type: 'string', hideable: false },
  { field: 'asin', headerName: 'ASIN', type: 'string', hideable: false },
  {
    field: 'drafts',
    headerName: 'Personal/Shared Draft Name',
    type: 'string',
    hideable: false,
    valueGetter: (_, row) => {
      let drafts = row?.drafts?.map(value => value?.name) || [];
      if (isInPersonalDraft(row.primary_isbn)) {
        drafts = ['Personal Draft', ...drafts];
      }
      return drafts.join(', ');
    },
  },
];

export const NOMINATION_PARENT_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, type: 'string' },
  {
    field: 'colCount',
    headerName: 'Column Count',
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
  },
];

export const ORDER_COLUMNS = [
  {
    field: 'order',
    headerName: 'Order',
    flex: 0.5,
    align: 'center',
    type: 'number',
    headerAlign: 'center',
    valueGetter: value => value + 1,
  },
  {
    field: 'title',
    flex: 1,
    headerName: 'Column Name',
    type: 'string',
  },
];

export const CHILD_COLUMNS = [{ field: 'title', headerName: 'Column Name', flex: 1, type: 'string' }];

export const RESTRICTED_TERRITORY_COLUMNS = [
  {
    field: 'publishers',
    headerName: 'Publisher',
    flex: 1,
    valueGetter: value => value?.join(', ') || 'NA',
    type: 'string',
  },
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    renderCell: ({ row }) => displayTitleLink(row.primary_isbn, row.title),
    type: 'string',
  },
  {
    field: 'primary_isbn',
    headerName: 'ISBN',
    width: 150,
    type: 'string',
  },
  {
    field: 'countries',
    headerName: 'Country',
    valueGetter: value => value?.join(', ') || 'NA',
    type: 'string',
    flex: 1,
  },
  {
    field: 'groups',
    headerName: 'User group which has rights',
    valueGetter: value => value?.join(', ') || 'NA',
    type: 'string',
    flex: 1,
  },
];

export const PERFERENCE_TYPE_COLUMNS = [
  { field: 'name', headerName: 'Preference Type', flex: 1, type: 'string' },
  { field: 'isDefault', headerName: 'Default', flex: 0.75, type: 'string' },
];

export const ADD_EDIT_SHARED_DRAFT_COLUMNS = [
  { field: 'name', headerName: 'Name', flex: 1, type: 'string' },
  {
    field: 'totalDraftTitles',
    headerName: 'Title Count',
    flex: 1,
  },
  {
    field: 'createdbyname',
    headerName: 'Created By',
    width: 100,
    valueGetter: value => {
      if (value) {
        if (value.first_name || value.last_name) {
          return value.first_name + ' ' + value.last_name;
        } else return value.email;
      } else return '';
    },
    type: 'string',
    flex: 1,
  },
];

export const BOOK_SEARCH_RESULTS_COLUMNS = bookColumnFormatter => [
  {
    field: 'title',
    headerName: 'Title',
    flex: 1,
    type: 'string',
    renderCell: ({ value, row }) => bookColumnFormatter(value, row, 'title'),
  },
  {
    field: 'authors',
    headerName: 'Authors',
    flex: 1,
    type: 'string',
    renderCell: ({ value, row }) => bookColumnFormatter(value, row, 'authors'),
  },
];

export const VALIDATE_LIST_COLUMNS = [
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'primary_isbn', headerName: 'ISBN', flex: 1, type: 'string' },
  { field: 'promoprice', headerName: 'Promoprice', flex: 1 },
  { field: 'authors', headerName: 'Authors', flex: 1, type: 'string' },
  { field: 'campaigntypeexceptionsName', headerName: 'Campaign Type Exceptions', flex: 1, type: 'string' },
  {
    field: 'start_date',
    headerName: 'Start Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'end_date',
    headerName: 'End Date',
    flex: 1,
    ...dateColumnConfig,
  },
];

export const DRAFT_EDITABLE_COLUMNS = [
  {
    field: 'campaignStartDate',
    headerName: 'Start Date',
    editable: true,
    width: 120,
  },
  {
    field: 'campaignEndDate',
    headerName: 'End Date',
    editable: true,
    width: 120,
  },
  {
    field: 'campaignName',
    headerName: 'Campaign Name',
    editable: true,
    width: 150,
    type: 'string',
  },
  {
    field: 'campaignType',
    headerName: 'Campaign Type',
    editable: true,
    width: 150,
    type: 'string',
  },
];

export const CAMPAIGN_TYPE_GROUP_COLUMNS = [
  {
    field: 'parentCampaignTypeDetails',
    headerName: 'Parent Campaign Type Group',
    flex: 1,
    type: 'string',
    valueGetter: params => {
      //Concatenate the text values of parentCampaignTypeDetails for search
      return params.length ? params?.map(parentDetail => parentDetail.text).join(', ') : '';
    },
    renderCell: params => (
      <Box>
        {params.value.split(', ').map((text, index) => (
          <div key={index}>{text}</div>
        ))}
      </Box>
    ),
  },
  {
    field: 'childCampaignTypeDetails',
    headerName: 'Child Campaign Type Groups',
    flex: 1,
    type: 'string',
    valueGetter: params => {
      // Concatenate the text values of childCampaignTypeDetails for search
      return params.length ? params?.map(childDetail => childDetail.text).join(', ') : '';
    },
    renderCell: params => (
      <Box>
        {params.value.split(', ').map((text, index) => (
          <div key={index}>{text}</div>
        ))}
      </Box>
    ),
  },
  {
    field: 'name',
    headerName: 'Custom Name For Campaign Type Group',
    flex: 1,
    type: 'string',
  },
];

export const LENDING_DEALS_TABLE_COLUMNS = [
  { field: 'primary_isbn', headerName: 'ISBN', width: 130 },
  {
    field: 'title',
    headerName: 'Title',
    width: 150,
    renderCell: params => generateBookDetailLink(params.row.primary_isbn, params.row.title),
  },
  {
    field: 'authors',
    headerName: 'Authors',
    width: 150,
    valueGetter: value =>
      value
        ?.filter(value => value.role === 1)
        .map(value => value.display_name)
        ?.join(', '),
  },
  { field: 'asin', headerName: 'Asin', width: 130 },
  { field: 'publisher', headerName: 'Publisher', width: 150 },
  { field: 'batch', headerName: 'Batch', width: 150, valueGetter: (_, row) => row?.bookdata?.batch },
  {
    field: 'bookScore',
    headerName: 'Book Score',
    flex: 1,
    minWidth: 80,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'firstHalfAvg',
    headerName: '1st Half Avg',
    flex: 1,
    minWidth: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'secondHalfAvg',
    headerName: '2nd Half Avg',
    flex: 1,
    minWidth: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'series_name',
    headerName: 'Series Name',
    width: 150,
    renderCell: displayHtmlContent,
  },
  {
    field: 'series',
    headerName: 'Series',
    width: 80,
    valueGetter: (_, row) => (row.series_name ? 'Yes' : 'No'),
  },
  { field: 'volume', headerName: 'Series Volume', width: 100, type: 'number', headerAlign: 'center', align: 'center' },
  {
    field: 'totalNumberOfTitlesInSeriesWithSameAuthor',
    headerName: 'Total Books in the series by the same author',
    width: 150,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'isAllTitlesRecommendedForAuthorWithSameSeries',
    headerName: 'With this nomination will all series books being in the results by same author',
    width: 150,
  },
  {
    field: 'totalTitlesRunningInKUBySameAuthor',
    headerName: 'Total number of titles in KU by same Author',
    width: 150,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'totalTitlesRunningInKUBySameAuthorAndSameSeries',
    headerName: 'Total number of titles in KU by same author and same series',
    width: 150,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  { field: 'bundle', headerName: 'Bundle', width: 150 },
  {
    field: 'customcategories',
    headerName: 'ORM Categories',
    width: 200,
    type: 'string',
    valueGetter: (_, row) => row?.customcategories?.map(value => value?.Name)?.join(', ') || '',
  },
  { field: 'bisacs', headerName: 'Bisacs', width: 300, valueGetter: value => value?.join(', ') || '' },
  { field: 'pub_date', headerName: 'Pub Date', ...dateColumnConfig },
  { field: 'onsaledate', headerName: 'On SaleDate', ...dateColumnConfig },
  { field: 'frontlist', headerName: 'FrontList', width: 80 },
  {
    field: 'awards',
    headerName: 'Awards',
    flex: 1,
    minWidth: 130,
    valueGetter: value => value?.map(value => value.awardname),
  },
  {
    field: 'dlp',
    headerName: 'US DLP',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 100,
    valueGetter: (_, row) => row?.bookdata?.US?.dlp,
  },
  {
    field: 'last30Units',
    headerName: 'Amazon Units sold last 30 days',
    type: 'number',
    width: 150,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row.bookdata?.US?.salesByRetailer?.Amazon?.last30Units,
  },
  {
    field: 'last30Proceeds',
    headerName: 'Amazon Proceeds last 30 days ',
    type: 'number',
    width: 150,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row.bookdata?.US?.salesByRetailer?.Amazon?.last30Proceeds,
  },

  {
    field: 'last90GVDaily',
    headerName: 'Amazon GV last 90 days',
    type: 'number',
    width: 150,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row.bookdata?.US?.araTraffic?.last90GVDaily,
  },
  {
    field: 'last90Units',
    headerName: 'Amazon Units sold last 90 days',
    type: 'number',
    width: 150,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row.bookdata?.US?.salesByRetailer?.Amazon?.last90Units,
  },
  {
    field: 'last90Proceeds',
    headerName: 'Amazon Proceeds last 90 days',
    type: 'number',
    width: 150,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row.bookdata?.US?.salesByRetailer?.Amazon?.last90Proceeds,
  },
  {
    field: 'last30consumerPrice',
    headerName: 'Average Consumer Price over the previous 30 days',
    type: 'number',
    width: 150,
    align: 'left',
    headerAlign: 'left',
    valueGetter: (_, row) => row.bookdata?.US?.consumerPrice?.last30consumerPrice,
  },
  {
    field: 'latestRating',
    headerName: 'Amazon Rating',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 120,
    valueGetter: (_, row) => row.bookdata?.US?.latestRating,
  },
  {
    field: 'latestReviews',
    headerName: 'Amazon Reviews',
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 120,
    valueGetter: (_, row) => row.bookdata?.US?.latestReviews,
  },
  {
    field: 'lastCampaignTypeName',
    headerName: 'Last Campaign type',
    width: 150,
    valueGetter: (_, row) => row?.bookdata?.campaignInfo?.lastCampaignTypeName,
    type: 'string',
  },
  {
    field: 'lastCampaignEndDate',
    headerName: 'Last Campaign End Date',
    width: 150,
    valueGetter: (_, row) => row?.bookdata?.campaignInfo?.lastCampaignEndDate,
  },
  {
    field: 'nextCampaignTypeName',
    headerName: 'Next Campaign Type',
    width: 150,
    valueGetter: (_, row) => row?.bookdata?.campaignInfo?.nextCampaignTypeName,
  },
  {
    field: 'nextCampaignStartDate',
    headerName: 'Next Campaign Start Date',
    width: 150,
    valueGetter: (_, row) => row?.bookdata?.campaignInfo?.nextCampaignStartDate,
  },
  {
    field: 'lastBookBubCampaignStartDate',
    headerName: 'Title in last BookBub Campaign start date',
    width: 150,
    valueGetter: (_, row) => {
      const bookbubFeaturedDealsCampaign = row?.bookdata?.campaignInfo?.byCampaignType?.find(
        campaign => campaign?.campaignTypeId === 3
      );
      return bookbubFeaturedDealsCampaign?.lastCampaignStartDate === 'N/A'
        ? bookbubFeaturedDealsCampaign?.nextCampaignStartDate
        : bookbubFeaturedDealsCampaign?.lastCampaignStartDate;
    },
  },
  {
    field: 'previouslyInKU',
    headerName: 'Previously in KU',
    width: 150,
    valueGetter: (_, row) =>
      isValidDateFormat(row?.kindleUnlimitedCampaign?.enddate) ? new Date(row?.kindleUnlimitedCampaign?.enddate) : null,
    valueFormatter: value => FormatDateString(value, 'MM/DD/YYYY'),
    type: 'date',
  },
  {
    field: 'isAvailableInPrimeCampaign',
    headerName: 'Availability in Prime',
    width: 150,
    valueGetter: (_, row) => row?.activePrimeReadingCampaign?.name,
  },
  {
    field: 'reason',
    headerName: 'Reason',
    width: 250,
  },
];

export const TSD_REPORT_COLUMNS = [
  { headerName: 'Title', field: 'title', type: 'string', width: 150 },
  { headerName: 'Primary Isbn', field: 'primary_isbn', width: 150 },
  { headerName: 'Asin', field: 'asin', type: 'string', width: 150 },
  {
    headerName: 'Author',
    field: 'author',
    width: 150,
    type: 'string',
    renderCell: ({ row }) => generateAuthorSocialDataLink(row.authorFirebrandId, row.author),
  },
  {
    headerName: 'Series Name',
    field: 'seriesName',
    width: 150,
    type: 'string',
    renderCell: ({ value }) => {
      return <Markup content={value} />;
    },
  },
  {
    headerName: 'Milestone Event Name',
    field: 'milestone',
    type: 'string',
    width: 150,
  },
  { headerName: 'Publisher Name', field: 'publisher', type: 'string', width: 100 },
  { headerName: 'Top Opportunity Title', field: 'isTopOpportunityTitle', width: 100, type: 'bool' },
  {
    headerName: 'Revenue',
    field: 'orderedRevenue',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    headerName: 'Change in Revenue over Prior Period',
    field: 'orderedRevenuePriorDay',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    valueFormatter: value => {
      return `${value}`;
    },
  },
  { headerName: 'Units', field: 'orderedUnits', width: 100, type: 'number', align: 'left', headerAlign: 'left' },
  {
    headerName: 'Change in Units over Prior Period',
    field: 'orderedUnitsPriorDay',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    valueFormatter: value => {
      return `${value}`;
    },
  },
  {
    headerName: 'Average Sales Price',
    field: 'avgSalesPrice',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    headerName: 'Average Sales Price - Prior Period',
    field: 'avgSalesPricePriorDay',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    headerName: 'Change in Glance View – Prior Period',
    field: 'GVChangePriorDay',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    valueFormatter: value => {
      return `${value}`;
    },
    classes: (cell, row) => {
      if (row.GVChangePriorDay) {
        const data = !Object.is(Number(row.GVChangePriorDay), NaN) ? Number(row.GVChangePriorDay) : '';
        if (!data) return;
        if (data >= 20 && data < 60) {
          return 'colorClassOne';
        } else if (data >= 60 && data < 100) {
          return 'colorClassTwo';
        } else if (data >= 100) {
          return 'colorClassThree';
        }
      }
      return;
    },
  },
  {
    headerName: '% Of total Glance Views',
    field: 'GVPct',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    classes: (cell, row) => {
      if (row.GVPct) {
        const data = !Object.is(Number(row.GVPct), NaN) ? Number(row.GVPct) : '';
        if (!data) return;
        if (data >= 0.18) {
          return 'colorClassFour';
        }
      }
    },
  },
  {
    headerName: 'Current Consumer Price',
    field: 'CurrentConsumerPrice',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    headerName: 'GlanceView',
    field: 'lastGVDaily',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    headerName: 'Conversion Rate',
    field: 'lastConversionRateDaily',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  {
    headerName: 'Change In Conversion Rate - Prior Period',
    field: 'changeConversionPriorPeriod',
    width: 150,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    valueFormatter: value => {
      return `${value}`;
    },
  },
  { headerName: 'Last Campaign Type', field: 'lastCampaignType', type: 'string', width: 150 },
  {
    headerName: 'Last Campaign Start Date',
    field: 'lastCampaignStartDate',
    ...dateColumnConfig,
  },
  {
    headerName: 'Last Campaign End Date',
    field: 'lastCampaignEndDate',
    ...dateColumnConfig,
  },
  {
    headerName: 'Last Campaign Promo Price',
    field: 'lastCampaignPromoPrice',
    width: 100,
    type: 'number',
    align: 'left',
    headerAlign: 'left',
  },
  { headerName: 'Last Campaign Type for Author', field: 'lastAuthorCampaignType', type: 'string', width: 150 },
  {
    headerName: 'Last Campaign Start date for Author',
    field: 'lastAuthorCampaignStartDate',
    ...dateColumnConfig,
  },
  {
    headerName: 'Last Campaign End Date for Author',
    field: 'lastAuthorCampaignEndDate',
    ...dateColumnConfig,
  },
  {
    headerName: 'Campaign by Author on Same Date',
    field: 'islastAuthorCampaignDateEqRepDate',
    width: 150,
    type: 'bool',
  },
];

export const SUBMITED_SUBMISSION_COLUMNS = [
  { field: 'name', headerName: 'Submission Name', flex: 1, type: 'string' },
  {
    field: 'campaigntype',
    headerName: 'Campaign Type',
    flex: 1,
    type: 'string',

    renderCell: ({ row }) => {
      return row.campaigntypename ? row.campaigntypename : '';
    },
    valueGetter: (_, row) => row.campaigntypename,
  },
  {
    field: 'startdate',
    headerName: 'Start Date',
    width: 100,
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    width: 100,
    ...dateColumnConfig,
  },
  { field: 'createdbyname', headerName: 'Created By', flex: 1, type: 'string' },
];

export const SUBMISSION_ERROR_COLUMNS = [
  { field: 'name', headerName: 'Submission Name', flex: 1, type: 'string' },
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  {
    field: 'startdate',
    headerName: 'Start Date',
    width: 100,
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    width: 100,
    ...dateColumnConfig,
  },
  { field: 'errorMessage', headerName: 'Error', flex: 1, type: 'string' },
];

export const SUBMISSION_MOVE_TITLE_ERROR_COLUMNS = [
  { field: 'title', headerName: 'Title', flex: 1, type: 'string' },
  { field: 'primary_isbn', headerName: 'Primary Isbn', flex: 1, type: 'string' },
  { field: 'authors', headerName: 'Authors', flex: 1, type: 'string' },
];
export const USER_LIST_COLUMNS = [
  {
    headerName: 'First Name',
    field: 'first_name',
    minWidth: 150,
  },
  {
    headerName: 'Last Name',
    field: 'last_name',
    minWidth: 150,
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
  },
  {
    headerName: 'Role',
    field: 'userrole',
    width: 150,
    valueGetter: value => value?.role,
  },
  {
    headerName: 'Group',
    field: 'groups',
    minWidth: 150,
    valueGetter: value => value?.join(','),
  },
  {
    headerName: 'Last login',
    field: 'last_login',
    minWidth: 150,
    ...dateColumnWithTimeConfig,
  },
  {
    headerName: 'Last Verified',
    field: 'last_verified_date',
    minWidth: 150,
    ...dateColumnWithTimeConfig,
  },
];

export const USER_ROLE_COLUMNS = allRoutePermission => {
  return [
    {
      headerName: 'Role',
      field: 'role',
      flex: 1,
    },
    {
      headerName: 'Action based Permissions',
      field: 'rolepermission',
      type: 'string',
      flex: 1,
      valueGetter: value => {
        if (value && value.length) {
          return value.map(v => `${v.name}`).join(',\n');
        } else {
          return '';
        }
      },
    },
    {
      headerName: 'Excluded Routes',
      field: 'routepermissions',
      type: 'string',
      flex: 1,
      valueGetter: params => {
        // Ensure params.value is an array, or initialize it as an empty array
        const includedRouteIds = Array.isArray(params) ? params : [];

        // Filter out the routes that are not included in routepermissions
        const excludedRoutes = allRoutePermission
          .filter(route => !includedRouteIds.includes(route._id.toString())) // Exclude included routes
          .map(route => route.title) // Map the remaining (excluded) routes to their titles
          .join(',\n'); // Join the titles with a newline character

        return excludedRoutes || 'Full Permission';
      },
    },
  ];
};

export const TRIGGER_TITLE_HISTORY_COLUMNS = [
  {
    field: 'name',
    headerName: 'Label',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'previous',
    headerName: 'Previous value',
    flex: 1,
    renderCell: renderTriggerHistoryCell,
  },
  {
    field: 'updated',
    headerName: 'Updated value',
    flex: 1,
    renderCell: renderTriggerHistoryCell,
  },
  {
    field: 'approvedBy',
    headerName: 'Approved By',
    flex: 0.5,
    minWidth: 120,
  },
  {
    field: 'updatedBy',
    headerName: 'Updated By',
    flex: 0.5,
    minWidth: 120,
  },
  {
    field: 'version',
    headerName: 'Version',
    minWidth: 80,
  },
];

export const BULK_DELETE_COLUMNS = [
  {
    field: 'title',
    headerName: 'Title',
    minWidth: 150,
    flex: 1,
  },
  { field: 'isbn', headerName: 'Primary ISBN', flex: 1, type: 'string' },
  { field: 'asin', headerName: 'ASIN', flex: 1, type: 'string' },
  {
    field: 'start_date',
    headerName: 'Upload Start Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'end_date',
    headerName: 'Upload End Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'campaign',
    headerName: 'Current Campaign Name',
    flex: 1,
    renderCell: ({ row }) => generateCampaignLinkNew(row?.campaign?._id, row?.campaign?.name),
    valueGetter: (_, row) => {      
      return row?.campaign?.name;
    },
  },
  {
    field: 'campaignstartdate',
    headerName: 'Start Date',
    flex: 1,
    valueGetter: (_, row) => {
      return isValidDateFormat(row?.campaign?.startdate) ? new Date(row?.campaign?.startdate) : null;
    },
    valueFormatter: value => FormatDateString(value, 'MM/DD/YYYY'),
  },
  {
    field: 'campaignenddate',
    headerName: 'End Date',
    flex: 1,
    valueGetter: (_, row) => {
      return isValidDateFormat(row?.campaign?.enddate) ? new Date(row?.campaign?.enddate) : null;
    },
    valueFormatter: value => FormatDateString(value, 'MM/DD/YYYY'),
  },
];

export const BULK_DELETE_CREATED_CAMPAIGN_COLUMNS = [
  {
    field: 'name',
    headerName: 'name',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => generateCampaignLinkNew(row?._id, row?.name),
  },
  {
    field: 'startdate',
    headerName: 'Start Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    flex: 1,
    ...dateColumnConfig,
  },
];

export const BULK_DELETE_INVALID_TITLE_COLUMNS = [
  { field: 'isbn', headerName: 'Primary ISBN', flex: 1, type: 'string', renderCell: displayError },
  { field: 'asin', headerName: 'ASIN', flex: 1, type: 'string', renderCell: displayError },
  {
    field: 'start_date',
    headerName: 'Upload Start Date',
    flex: 1,
    ...dateColumnConfig,
  },
  {
    field: 'end_date',
    headerName: 'Upload End Date',
    flex: 1,
    ...dateColumnConfig,
  },
  { field: 'countries', headerName: 'Countries', flex: 1, type: 'string' },
];
