export const ROUTES = {
  widgets: 'WIDGETS',
  campaign: 'CAMPAIGN',
  filterCampaigns: 'FILTER_CAMPAIGNS',
  nomination: 'NOMINATION',
  newCampaign: 'NEW_CAMPAIGN',
  sharedDraft: 'SHARED_DRAFT',
  bulkDelete: 'BULK_DELETE',
  triggerTitles: 'TRIGGER_TITLES',
  triggerTitlesConfiguration: 'TRIGGER_TITLES_CONFIGURATION',
  viewExistingConfiguration: 'VIEW_EXISTING_CONFIGURATION',
  viewTriggerResult: 'VIEW_TRIGGER_RESULT',
  reports: 'REPORTS',
  opportunityTitleReport: 'OPPORTUNITY_TITLE_REPORT',
  priorityTitleReport: 'PRIORITY_TITLE_REPORT',
  recommendation: 'RECOMMENDATION',
  haloCampaign: 'HALO_CAMPAIGN',
  blastRecommendation: '250_BLAST_RECOMMENDATION',
  blastConfiguration: '250_BLAST_CONFIGURATION',
  contentNldealsRecommendation: 'CONTENT_NL_DEALS_RECOMMENDATION',
  contentNldealsConfiguration: 'CONTENT_NL_DEALS_CONFIGURATION',
  lendingDealsRecommendation: 'LENDING_DEALS_RECOMMENDATION',
  lendingDealsConfiguration: 'LENDING_DEALS_CONFIGURATION',
  activation: 'ACTIVATION',
  addNewServiceRequest: 'ADD_NEW_SERVICE_REQUEST',
  viewServiceRequest: 'VIEW_SERVICE_REQUEST',
  clientProfile: 'CLIENT_PROFILE',
  soldSlotConfiguration: 'SOLD_SLOT_CONFIGURATION',
  soldSlotAvailability: 'SOLD_SLOT_AVAILABILITY',
  monthlyReport: 'MONTHLY_REPORT',
  configuration: 'CONFIGURATION',
  campaignType: 'CAMPAIGN_TYPE',
  campaignTypePrioritization: 'CAMPAIGNTYPEPRIORITIZATION',
  campaignTypeGroup: 'CAMPAIGN_TYPE_GROUP',
  customCategoryMapping: 'CUSTOM_CATEGORY_MAPPING',
  blocklist: 'BLOCKLIST',
  restrictTerritory: 'RESTRICT_TERRITORY',
  externalSubmission: 'EXTERNAL_SUBMISSION',
  minimumPromoPrice: 'MINIMUM_PROMO_PRICE',
  draftPromoPrice: 'DRAFT_PROMO_PRICE',
  generateRetailerLinks: 'GENERATE_RETAILER_LINKS',
  editBook: 'EDIT_BOOK',
  bookbub: 'BOOKBUB',
  pageCountConfiguration: 'PAGE_COUNT_CONFIGURATION',
  priorityConfiguration: 'PRIORITY_CONFIGURATION',
  costConfiguration: 'COST_CONFIGURATION',
  pricelog: 'PRICELOG',
  pricelogStatusViewer: 'PRICELOG_STATUS_VIEWER',
  dlpUpdate: 'DLP_UPDATE',
  uploadMasterPriceGrid: 'UPLOAD_MASTER_PRICE_GRID',
  others: 'OTHERS',
  milestoneCalendar: 'MILESTONE_CALENDAR',
  authorSocialData: 'AUTHOR_SOCIAL_DATA',
  orionScheduler: 'ORION_SCHEDULER',
  upload: 'UPLOAD',
  uploadPartnerTitle: 'UPLOAD_PARTNER_TITLE',
  uploadMilestoneEvents: 'UPLOAD_MILESTONE_EVENTS',
  audioContract: 'AUDIO_CONTRACT',
  uploadAudioContract: 'UPLOAD_AUDIO_CONTRACT',
  viewAudioContract: 'VIEW_AUDIO_CONTRACT',
  utilities: 'UTILITIES',
  firebrandReports: 'FIREBRAND_REPORTS',
  nominationFilterTracking: 'NOMINATION_FILTER_TRACKING',
  campaignHelp: 'CAMPAIGN_HELP',
  downpriceCampaignHelp: 'DOWNPRICE_CAMPAIGN_HELP',
  aggregatedHistoricalDataLog: 'AGGREGATED_HISTORICAL_DATA_LOG',
  trafficLightReport: 'TRAFFIC_LIGHT_REPORT',
  userManagement: 'USER_MANAGEMENT',
  userRole: 'USER_ROLE',  
};
