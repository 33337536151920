import { FormatDateString, isValidDateFormat, FormatDateTimeInESTTimeZone } from './DateTimeHelper';
import { Link } from 'react-router-dom';
import { Tooltip, Typography, IconButton } from '@mui/material';
import {
  DeleteSharp,
  CreateSharp,
  PreviewSharp,
  FileCopySharp,
  RssFeedSharp,
  Receipt,
  AddBoxSharp,
  FacebookSharp,
  CloudDownloadOutlined,
  Share,
  EditCalendar,
  History,
  Visibility,
} from '@mui/icons-material';

export const displayUserName = value => {
  let name = value?.email;
  if (value?.first_name || value?.last_name) name = value?.first_name + ' ' + value?.last_name;
  return name;
};

export const displayDate = ({ value }) => {
  if (value) return FormatDateString(value, 'MM/DD/YYYY');
  else return '';
};

export const dateColumnConfig = {
  type: 'date',
  width: 140,
  minWidth: 140,
  valueGetter: value => (isValidDateFormat(value) ? new Date(FormatDateString(value, 'MM/DD/YYYY')) : null),
  valueFormatter: value => FormatDateString(value, 'MM/DD/YYYY'),
};

export const dateColumnWithTimeConfig = {
  type: 'dateTime',
  width: 180,
  minWidth: 180,
  valueGetter: value => (isValidDateFormat(value) ? new Date(value) : null),
  valueFormatter: value => (value ? FormatDateTimeInESTTimeZone(value, 'MM/DD/YYYY HH:mm') : '-'),
};

export const displayTitleLink = (isbn, title) => {
  if (isbn && title)
    return (
      <Link to={{ pathname: `/book/${isbn}` }} target={'_blank'}>
        {title}
      </Link>
    );
};

export const displayHtmlContent = ({ value }) => {
  if (value) {
    return (
      <Typography
        variant='caption'
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      ></Typography>
    );
  }
};

export const displayAuthorLink = ({ row }) => {
  let links = row?.authordetails?.map((author, i) => {
    let separator = '';
    if (i < row?.authordetails.length - 1) separator = ', ';

    return (
      <span key={i}>
        <Link
          to={{
            pathname: '/others/social-data',
            search: `?id=${author.firebrandId}&name=${author.name}`,
          }}
          target={'_blank'}
        >
          {author.name}
        </Link>
        {separator}
      </span>
    );
  });
  return links;
};

/* 
  This function will use to define action columns for any ORMDataGridV2 component. Client should pass actionDef object in following format
  actionDef =[
    {
      name: 'Edit'
      callbackFunction: function
    }
  ]
*/
export const getActionColumns = actionDef => {
  return {
    headerName: 'Actions',
    field: 'actions',
    type: 'actions',
    width: 140,
    getActions: ({ row }) => [
      ...actionDef.map(x => {
        if (x.name === 'Edit') {
          return getEditAction(row, x.callbackFunction);
        }
        if (x.name === 'Preview') {
          return getPreviewAction(row, x.callbackFunction);
        }
        if (x.name === 'Delete') {
          return getDeleteAction(row, x.callbackFunction);
        }
        if (x.name === 'Clone') {
          return getCloneAction(row, x.callbackFunction);
        }
        if (x.name === 'Feed') {
          return getFeedAction(row, x.callbackFunction);
        }
        if (x.name === 'Invoice') {
          return getInvoiceAction(row, x.callbackFunction);
        }
        if (x.name === 'History') {
          return getHistoryAction(row, x.callbackFunction);
        }
        return <></>;
      }),
    ],
  };
};

export const getPreviewAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Preview Next Trigger Result' key='preview'>
      <IconButton size='small' onClick={e => callbackFunction(row)} sx={{ p: 0.2 }}>
        <PreviewSharp sx={{ color: '#008000' }} fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const getEditAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Edit' key='edit'>
      <IconButton size='small' onClick={e => callbackFunction(row)} sx={{ p: 0.2 }}>
        <CreateSharp sx={{ color: '#212B36' }} fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const getDeleteAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Delete' key='delete'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.2 }}>
        <DeleteSharp color='error' fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const getCloneAction = (row, callbackFunction, key) => {
  return (
    <Tooltip title='Clone' key='clone'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.2 }}>
        <FileCopySharp fontSize='small' color='success' />
      </IconButton>
    </Tooltip>
  );
};

export const getFeedAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Feed' key='feed'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.2 }}>
        <RssFeedSharp fontSize='small' color='error' />
      </IconButton>
    </Tooltip>
  );
};

export const getInvoiceAction = (row, callbackFunction) => {
  return (
    <Tooltip title='View Invoice' key='view-invoice'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.2 }}>
        <Receipt color='success' fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const getViewAction = (row, callbackFunction) => {
  return (
    <Tooltip title='View Detail' key='view'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.2 }}>
        <Visibility color='success' fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const getHistoryAction = (row, callbackFunction) => {
  return (
    <Tooltip title='View History' key='view-history'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.2 }}>
        <History color='secondary' fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const getAddAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Add titles a post' key='add-titles-a-post'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.3, ml: 0.5 }}>
        <AddBoxSharp sx={{ color: '#212B36' }} fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const getFacebookAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Edit Facebook Campaign' key='edit-facebook-campaign'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.3, ml: 0.5 }}>
        <FacebookSharp fontSize='small' sx={{ color: '#1178F2' }} />
      </IconButton>
    </Tooltip>
  );
};

export const getLoadAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Load' key='load'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.3, ml: 0.5 }}>
        <CloudDownloadOutlined color='success' fontSize='small' />
      </IconButton>
    </Tooltip>
  );
};

export const getShareAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Share filters with other users' key='Share-filters-with-other-users'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.3, ml: 0.5 }}>
        <Share fontSize='small' color='primary' />
      </IconButton>
    </Tooltip>
  );
};

export const getEditCalendarAction = (row, callbackFunction) => {
  return (
    <Tooltip title='Re-Schedule Service Request' key='re-schedule-service-request'>
      <IconButton size='small' onClick={() => callbackFunction(row)} sx={{ p: 0.3, ml: 0.5 }}>
        <EditCalendar fontSize='small' color='info' />
      </IconButton>
    </Tooltip>
  );
};
